import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
//import { User } from '../models/user';
import { environment } from '../../environments/environment'
import { from } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  getList(page: number, perpage: number, sort:any, type: string,search:string) {
   let postData = { "page": page, "pageLimit": perpage, "sort": sort, "type": type,"search":search }
    return this.http.post<any>(`${environment.apiUrl}user/list/accountant`, postData);
  }
  getListAccountant() {
    let postData = {}
     return this.http.post<any>(`${environment.apiUrl}user/list/accountantfind`, postData);
   }
 
  getListAllUserlist(page: number, perpage: number, sort:any, type: string,search:string) {
    // let postData = {"page":1,"pageLimit":2}
    let postData = { "page": page, "pageLimit": perpage, "sort": sort, "type": type,"search":search }
     return this.http.post<any>(`${environment.apiUrl}user/list/userfornotification`, postData);
   }  

  getfinancialList(page: number, perpage: number, sort:any, type: string,search:string) {
   
   // let postData = {"page":1,"pageLimit":2}
    let postData = { "page": page, "pageLimit": perpage, "sort": sort, "type": type,"search":search }
    return this.http.post<any>(`${environment.apiUrl}user/list/agent`, postData);
  }

  getCatalogList(page: number, perpage: number, sort:any, type: string,search:string) {
    let postData = { "page": page, "pageLimit": perpage, "sort": sort, "type": type,"search":search }
    return this.http.post<any>(`${environment.apiUrl}/admin/catalog-list`, postData);
  }

  getLotsList(page: number, perpage: number, sort:any, type: string,search:string,catalogId:number) {
    let postData = {"catalogId":catalogId, "page": page, "pageLimit": perpage, "sort": sort, "type": type,"search":search }
    return this.http.post<any>(`${environment.apiUrl}/admin/lots-list`, postData);
  }

  getAuctionList(page: number, perpage: number, sort:any, type: string,search:string) {
    let postData = { "page": page, "pageLimit": perpage, "sort": sort, "type": type,"search":search }

    return this.http.post<any>(`${environment.apiUrl}/admin/auction-list`, postData);
  }


  getkeywordList(page: number, perpage: number, sort:any, type: string,search:string) {
    let postData = { "page": page, "pageLimit": perpage, "sort": sort, "type": type,"search":search }
    return this.http.post<any>(`${environment.apiUrl}/admin/keyword-list`, postData);
  }

  getConditionList(page: number, perpage: number, sort:any, type: string,search:string) {
    let postData = { "page": page, "pageLimit": perpage, "sort": sort, "type": type,"search":search }
    return this.http.post<any>(`${environment.apiUrl}/admin/condition-list`, postData);
  }

  getPickupList(page: number, perpage: number, sort:any, type: string,search:string) {
    let postData = { "page": page, "pageLimit": perpage, "sort": sort, "type": type,"search":search }
    return this.http.post<any>(`${environment.apiUrl}/admin/pickup-list`, postData);
  }

  getNotificationList(page: number, perpage: number, sort:any, type: string,search:string) {
    let postData = { "page": page, "pageLimit": perpage, "sort": sort, "type": type,"search":search }
    return this.http.post<any>(`${environment.apiUrl}notify/brdnotify/list`, postData);
  }
 changeticketStatus(postData){
    return this.http.post<any>(`${environment.apiUrl}contact-us/changeTicketStatus`, postData);
 }

 changeticketStatussing(postData){
  return this.http.post<any>(`${environment.apiUrl}contact-us/saveTicketstatusset`, postData);
}

 getNotificationbyid(id) {
    let postData = {"id": id };
   return this.http.post<any>(`${environment.apiUrl}notify/brdnotify/byid`, postData);
  }
 contactusgetfilter(type: number) {
    let postData
    if(type==0){
       postData = {"page": 1000 };
     
    }
    else{
       postData = {"type":type };
    
    }
    return this.http.post<any>(`${environment.apiUrl}contact-us/getticket`, postData);
   
  }

  ticketFiletrBytype(page: number, perpage: number, sort:any, type: string,search:string) {
    let postData = {"page": page , "pageLimit":perpage , "type":type };
   return this.http.post<any>(`${environment.apiUrl}contact-us/getticket`, postData);
  }
  contactusget(page: number, perpage: number, sort:any, type: string,search:string) {
    let postData = {"page": page , "pageLimit":perpage , "type":type ,"search":search};
   return this.http.post<any>(`${environment.apiUrl}contact-us/getticket`, postData);
  }

  contactusgetbyid(id) {
    let postData = {"id": id };
   return this.http.post<any>(`${environment.apiUrl}contact-us/getticketbyid`, postData);
  }

  getNotificationListreport(page: number, perpage: number, sort:any, type: string,search:string) {
 //   let postData ={"profilePicture":"http://imageurl","businessName":"Tax company1","officeAddress":"36 bay11","mobile":"+6545454544","username":"nafeesf","password":"asdf12345","email":"nafeesf@taxvolt.com","firstName":"Nafees12","lastName":"Ahmed12","userType":"agent"};
 let postData ={}
    
    //{ "page": page, "pageLimit": perpage, "sort": sort, "type": type,"search":search }
    return this.http.post<any>(`${environment.apiUrl}reports/reports`, postData);
  }

  generateAuctionId(){
    let postData = {}
    return this.http.post<any>(`${environment.apiUrl}/catalogue/generate-action`, postData);
  }

  getCount(type: string) {
    let postData = { "type": type }
    return this.http.post<any>(`${environment.apiUrl}/admin/count`, postData);
  }
  logout(type: string) {
    let postData = { "type": type }
    return this.http.post<any>(`${environment.apiUrl}user/logout`, postData);
  }
  
  getLotImages(id: string) {
    return this.http.get<any>(`${environment.apiUrl}/lot/getlotImages/${id}`);
  }
 
  uploadmultiimg(data){
   let result=this.http.post<any>(`${environment.apiUrl}pt/signatureImage`, data);
    return result;
  }
  createfinancialUser(postData:any){
    return this.http.post<any>(`${environment.apiUrl}user/register/agent`, postData);
  }
  // createUser(postData:any){
  //   return this.http.post<any>(`${environment.apiUrl}user/register/accountant`, postData);
  // }

  getAccList(params){
    return this.http.post<any>(`${environment.apiUrl}user/admin/list`,params)
  }

  createUser(postData:any){

    return this.http.post<any>(`${environment.apiUrl}user/register/accountant`, postData);
  }
   saveNotification(postData:any){
    return this.http.post<any>(`${environment.apiUrl}notify/brdnotify`, postData);
  }
  notificationsearch(postData:any){
    return this.http.post<any>(`${environment.apiUrl}notify/brdnotify/search`, postData);
  }
 
  getusersearchdata(postData:any){
    return this.http.post<any>(`${environment.apiUrl}user/list/userListForSearch`, postData);
  }
  
  getusersearchdataforaccountant(postData:any){
    return this.http.post<any>(`${environment.apiUrl}user/list/userListForSearchAccountant`, postData);
  }

  getusersearchdatafinancialplanner(postData:any){
    return this.http.post<any>(`${environment.apiUrl}user/list/userListForSearchagent`, postData);
  }

  sendnotification(postData:any){
    return this.http.post<any>(`${environment.apiUrl}notify/notification`, postData);
  }
  
  createkeyWord(postData:any){
    return this.http.post<any>(`${environment.apiUrl}/keyword/create`, postData);
  }

  createCondition(postData:any){
    return this.http.post<any>(`${environment.apiUrl}/condition/create`, postData);
  }

  cretaePickup(postData:any){
    return this.http.post<any>(`${environment.apiUrl}/pickup/create`, postData);
  }

  createNotification(postData:any){
    return this.http.post<any>(`${environment.apiUrl}/notification/create`, postData);
  
  }
  deleteFinacialplanner(postData:any){
   
    return this.http.post<any>(`${environment.apiUrl}user/delete`,postData);
  }
  deleteUser(postData:any){
   
    return this.http.post<any>(`${environment.apiUrl}user/delete`,postData);
  }

  deleteAuction(id:number){
    return this.http.delete<any>(`${environment.apiUrl}/catalogue/remove-auction/${id}`);
  }

  deleteCatalog(id:number){
    return this.http.delete<any>(`${environment.apiUrl}/catalogue/remove/${id}`);
  }

  deleteKeyword(id:number){
    return this.http.delete<any>(`${environment.apiUrl}/keyword/remove/${id}`);
  }

  deleteCondition(id:number){
    return this.http.delete<any>(`${environment.apiUrl}/condition/remove/${id}`);
  }

  deletePickup(id:number){
    return this.http.delete<any>(`${environment.apiUrl}/pickup/remove/${id}`);
  }

  deleteNotification(id:number){
    return this.http.delete<any>(`${environment.apiUrl}/notification/remove/${id}`);
  }

  deleteLot(id:number){
    return this.http.delete<any>(`${environment.apiUrl}/lot/remove/${id}`);
  }

  sendNotification(id){
    return this.http.post<any>(`${environment.apiUrl}/notification/send`, {id:id});
  }
  
  getGgraphData(type){
    return this.http.post<any>(`${environment.apiUrl}/admin/getGraphData`, {gType:type});    
  }
 

  excelImport(id){
    return this.http.get<any>(`${environment.apiUrl}/catalogue/excel-import/${id}`,);
  }
  changePasswordaccountant(postData:any){
    return this.http.post<any>(`${environment.apiUrl}user/changeaccountant/password`, postData);
  }
  changePasswordadmin(postData:any){
    return this.http.post<any>(`${environment.apiUrl}user/changeadminwithold/password`, postData);
  }


  changePassword(postData:any){
    return this.http.patch<any>(`${environment.apiUrl}/user/change-password`, postData);
  }
}