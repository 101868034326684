import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service'
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from '../../services/alert.service';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpRequest, HttpEventType } from "@angular/common/http";
import { UserService } from '../../services/user.service'
declare  var swal:any;
declare  var $:any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  userData:any;
  userName:any;
  userId:any;
  changePasswordForm: FormGroup;
  submitted: boolean;
 
  constructor(private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,private formBuilder: FormBuilder,
    private http: HttpClient,  private userService: UserService,) { 

      if (this.authenticationService.currentUserValue) {

          this.userData = this.authenticationService.currentUserValue 
          this.userName = this.userData.name
          this.userId = this.userData.userId        
       }

       this.changePasswordForm = this.formBuilder.group({
        currPassword: ['', Validators.required], 
        password: ['', Validators.required],
        confmPassword:['', Validators.required],       
      },{
        validator: this.MustMatch('password', 'confmPassword')
      });
  

    }



  ngOnInit() {
    $(document).ready(function(){
      // Show hide popover
      $("body").click(function(){
    $(".dropdown-menu").hide();
      });
      $('#userProfileDropdown').click(function(){
        $('.dropdown-menu').show();
    });
      });
  
  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }


  logout(){
    this.authenticationService.logout(this.userId).subscribe(data=>{
      this.router.navigate(['/']);
    })
      
  }

  get f() { return this.changePasswordForm.controls; }

  
  showChangePassForm(){
    $("#changePasswordModal").modal('show');
  }

  
  onSubmit() {

    this.submitted = true;
    //this.alertService.clear();
    if (this.changePasswordForm.invalid) {
      return false;
    }

    //console.log(this.conditionForm.value.fname)
    //this.userService.createUser()

    let postData = {userid:this.userId, oldpassword: this.changePasswordForm.value.currPassword,newpassword:this.changePasswordForm.value.password}
    this.userService.changePasswordadmin(postData).subscribe(succ => {
      if (succ.status) {
        $("#changePasswordModal").modal('hide');
        swal({
          title: succ.message,
          padding: '2em'
        })
      } else {
        swal({
          title: succ.message,
          padding: '2em'
        })
      }

    })
  }

}
