import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute,NavigationEnd, } from '@angular/router';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  path:any
  constructor(private router: Router,
    ) {
      this.router.events.subscribe((res) => { 
        //console.log(this.router.url,"Current URL");
        this.path = this.router.url
      })
     }

  ngOnInit() {
    
    
  }

}
