import { Component, OnInit,AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpRequest, HttpEventType } from "@angular/common/http";
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user.service'
import { AlertService } from '../../services/alert.service';

declare var $: any
declare var select2: any
declare var App: any
declare var swal: any
declare var document: any

@Component({
  selector: 'app-assign-rrsp',
  templateUrl: './assign-rrsp.component.html',
  styleUrls: ['./assign-rrsp.component.scss']
})
export class AssignRrsp implements OnInit {
  userProfileForm: FormGroup;
  assignTicketform: FormGroup;
  changePasswordaccoutant: FormGroup;
  fname: string;
  lname: string;
  username: string;
  password: string;
  cpassword: string;
  email: string;
  mobile: string;
  company: string;
  userType: number;
  submitted: boolean = false;
  totalRows: number = 0;
  userList: any;
  accountantList: any;
  iddata:any;
  perpage: number = 10
  numOfPage: number = 0
  page = 1;
  sort: string = 'desc'
  search: string = ''
  listType: string = 'user'
  view: boolean;
  view1: boolean;
  view2: boolean;
  viewdiv: number = 1;
  taxinfo: any;
  ma: any;
  si: any;
  dp: any;
  sum: any;
  
  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private http: HttpClient, private router: Router, private userService: UserService, private alertService: AlertService,
  ) {
    this.iddata = this.route.snapshot.params['id'];

    this.viewdiv = this.route.snapshot.params['view'];

    this.view = true;

    if(this.viewdiv == 2){
      this.view = false
      this.view1 = true
      this.view2 = false
    }

    if(this.viewdiv == 3){
      this.view = false
      this.view1 = false
      this.view2 = true
    }
   }

  ngOnInit() {

    this.loadStyle('datatables', 'assets/plugins/table/datatable/datatables.css')
    this.loadStyle('dt-global_style', 'assets/plugins/table/datatable/dt-global_style.css')
    this.loadStyle('scrollspyNav', 'assets/css/scrollspyNav.css')
    this.loadStyle('custom-modal', 'assets/css/components/custom-modal.css')
    this.loadStyle('animate', 'assets/plugins/animate/animate.css')
    this.loadStyle('structure', 'assets/css/structure.css')
    this.assignTicketform = this.formBuilder.group({
      note: [''],
      assignTo: ['', Validators.required],
    });
 
    
   this.getUserList();
   this.getAllAccountantList();
  }

  get f() { return this.assignTicketform.controls; }
  
  loadStyle(idS: String, styleName: string) {
    // alert(styleName);
    const head = document.getElementsByTagName('head')[0];
    // alert('hi');
    let themeLink = document.getElementById(
      `${idS}`
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleName;
    } else {
      const style = document.createElement('link');
      style.id = `${idS}`;
      style.rel = 'stylesheet';
      style.href = `${styleName}`;

      head.appendChild(style);
    }
  }

  assignTicket() {
    
    this.submitted = true;
    this.alertService.clear();
     if (this.assignTicketform.invalid) {
     return false;
     }
    let postData =  {
      notes: this.assignTicketform.value.note,
      assignedToId:this.assignTicketform.value.assignTo,
      type: 'corporation',
      ticketId: this.iddata,
      ticketState:'2', 
     };
   this.userService.changeticketStatus(postData).subscribe(succ => {
      if (succ.status==200) {
        this.userService.changeticketStatussing(postData).subscribe(succ => {
        })
        this.assignTicketform.reset()
        this.submitted = false;
        this.ngOnInit();
    // this.alertService.success('assigned successfully');
        swal({
          title:'assigned successfully',
          padding: '2em'
        }).then((value) => {
          
        });
      } else {
        swal({
          title:succ.error.message,
          padding: '2em'
        })
      }

    })
  }



  ngAfterViewInit() {
    if (document.getElementById("load_screen") != null) {
      document.getElementById("load_screen").style.display = "none"
    }
    App.init();

  }

  getUserList() {
    this.userService.contactusgetbyid(this.iddata).subscribe(list => {
      if (list.status) {
        this.totalRows = list.output.resultCount;
        this.numOfPage = Math.ceil(this.totalRows / this.perpage)
        this.userList = list.output[0]
        this.taxinfo = list.output[0].preapreTax[0]

        this.ma = list.output[0].preapreTax[0].mailingAddress;
        this.si = list.output[0].preapreTax[0].spouseInformation
        this.dp = list.output[0].preapreTax[0].dependent
        this.sum = list.output[0].preapreTax[0].calculationSummary;
      }
    })
  }

  
  getAllAccountantList() {
    let params = {userType:"agent" , "page":1 , "pageLimit":500}
   this.userService.getAccList(params).subscribe(list => {
     if (list.status) {
       this.totalRows = list.output.resultCount;
       this.numOfPage = Math.ceil(this.totalRows / this.perpage)
       this.accountantList = list.output.list
       if(this.accountantList == 0){
         swal({
           title: "No Results Found",
           padding: '2em'
         })
       }
     }
   })
 }



  pagination(val: any) {
    //alert(val)
    if (val == 'prev') {
      this.page = this.page - 1
    } else if (val == 'next') {
      this.page = this.page + 1
    } else {
      this.page = parseInt(val)
    }
    if (this.page < 1) {
      this.page = 1
    }
    if (this.page <= this.numOfPage) {
      this.getUserList()
    }
  }

  sortList() {
    if (this.sort == 'desc') {
      this.sort = 'asc'
    } else if (this.sort == 'asc') {
      this.sort = 'desc'
    }
    this.getUserList()
  }

  accountsearch(event){
    let datasuggested:any ={ "search":event.target.value};
    if(datasuggested !=''){
    this.userService.getusersearchdataforaccountant(datasuggested).subscribe(list => {
      if (list.status==200) {
        this.userList = list.output
        if(list.output.length <= 0){
          swal({
            title: "No Results Found",
            padding: '2em'
          })
        }
      }
    })
  }

  }
  searchList() {
    this.search = document.getElementById('search').value
    this.page = 1
    this.getUserList()
  }

  createUser() {
    $(".bd-example-modal-lg").modal('show')
  }

  dismissModal() {
    this.submitted = false;
    $(".bd-example-modal-lg").modal('hide')
  }

}
