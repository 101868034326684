<app-header></app-header>
<!--  BEGIN MAIN CONTAINER  -->
<div class="main-container sidebar-closed sbar-open" id="container">
  <div class="overlay"></div>
  <div class="cs-overlay"></div>
  <div class="search-overlay"></div>
  <!--  BEGIN SIDEBAR  -->
  <div class="sidebar-wrapper sidebar-theme">
    <app-navigation></app-navigation>
  </div>
  <!--  END SIDEBAR  -->
  <!--  BEGIN CONTENT AREA  -->
  <div id="content" class="main-content" *ngIf="view">
    <div class="layout-px-spacing">
      <div class="page-header">
        <div class="page-title">
          <h3>View Ticket</h3>
        </div>
        <div class="page-title" style="float: right;cursor: pointer;color: white; font-size: 1rem">
          <h3 routerLink="/manage-ticket"><< Back</h3>
        </div>
      </div>
      <div class="row" id="cancel-row">
        <div class="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
          <div class="widget-content widget-content-area br-6">
            <div class="table-responsive mb-4 mt-4">
              <form [formGroup]="assignTicketform" autocomplete="off" (ngSubmit)="assignTicket()">
                <div id="zero-config_wrapper" class="dataTables_wrapper container-fluid dt-bootstrap4">
                  <div class="row">
                    <div class="col-xl-8 col-lg-8 col-sm-8">
                      <div class="mt-2"> <label>Ticket No. - {{ userList?.ticketnumber }}</label>
                      </div>
                      <div class="mt-2"><label>Description -{{taxinfo?.notes?taxinfo.notes:''}}</label></div>
                      <textarea placeholder="Describe  here..." formControlName="note" rows="4" cols="50"
                        [ngClass]="{ 'is-invalid': submitted && f.note.errors }">  </textarea>
                      <div *ngIf="submitted && f.note.errors" class="invalid-feedback">
                        <div *ngIf="f.note.errors.required">note is required</div>
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-sm-4">
                      <div class="mt-2"><label>Status - {{userList?.status ==1 ? "Open" : userList?.status == 2 ? "Assigned" :
                        userList?.status == 3 ? "In progress" : "close"}}</label></div>
                      <div class="dataTables_length mt-3" id="zero-config_length">
                        <label>Assigned to :
                          <select formControlName="assignTo" placeholder="assignTo"
                            [ngClass]="{ 'is-invalid': submitted && f.assignTo.errors }" class="form-control2">
                            <option value="" hidden selected *ngIf="!notfound">Please select</option>
                            <option value="" hidden selected *ngIf="notfound">Please add to assign</option>
                            <option *ngFor="let user of accountantList let i = index " [value]="user._id">
                              {{user.email}} </option>
                          </select>
                          <div *ngIf="submitted && f.assignTo.errors" class="invalid-feedback">
                            <div *ngIf="f.assignTo.errors.required">Assigned to is required.</div>
                          </div>
                        </label>
                      </div>
                      <input *ngIf="userList?.status == 1" type="submit" name="submit" value="submit" class="btn btn-info">
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--  END CONTENT AREA  -->



  <div id="content" class="main-content" *ngIf="view1">
    <div class="layout-px-spacing">
      <div class="page-header">
        <div class="page-title">
          <h3>Client Tax Information</h3>
        </div>
        <div class="page-title" style="float: right;cursor: pointer;color: white; font-size: 1rem">
          <h3 routerLink="/manage-ticket"><< Back</h3>
        </div>
      </div>
      <div class="row" id="cancel-row">
        <div class="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
          <div class="widget-content widget-content-area br-6">
            <div class="table-responsive mb-4 mt-4">
              <div id="zero-config_wrapper" class="dataTables_wrapper container-fluid dt-bootstrap4">
                <div id="toggleAccordion">
                  <div class="card">
                    <div class="card-header" id="headingTwo1">
                      <section class="mb-0 mt-0">
                        <div role="menu" class="" data-toggle="collapse" data-target="#defaultAccordionTwo"
                          aria-expanded="false" aria-controls="defaultAccordionTwo">
                          Personal Information <div class="icons"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                              height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down">
                              <polyline points="6 9 12 15 18 9"></polyline>
                            </svg></div>
                        </div>
                      </section>
                    </div>
                    <div id="defaultAccordionTwo" class="collapse show" aria-labelledby="headingTwo1"
                      data-parent="#toggleAccordion">
                      <div class="card-body">
                        <div class="row layout-top-spacing">
                          <div id="tableSimple" class="col-lg-12 col-12 layout-spacing">
                            <div class="statbox widget box box-shadow">

                             
                                <div class="table-responsive">
                                  <table class="table table-bordered mb-4">

                                    <tbody>
                                      <tr>
                                        <td>Name</td>
                                        <td>{{taxinfo?.suffix}} {{taxinfo?.firstName}} {{taxinfo?.initial}} {{taxinfo?.lastName}}</td>
                                      </tr>
                                      <tr>
                                        <td>Dob</td>
                                        <td>{{taxinfo?.dob}}</td>
                                      </tr>
                                      <tr>
                                        <td>Gender</td>
                                        <td>{{ taxinfo?.gender?taxinfo.gender:'' }}</td>
                                      </tr>
                                      <tr>
                                        <td>Filling Status</td>
                                        <td>{{taxinfo?.whatIsYourFilingStatus?taxinfo.whatIsYourFilingStatus:''}}</td>
                                      </tr>
                                      <tr>
                                        <td>SIN</td>
                                        <td>{{taxinfo?.socialInsuranceNumber?taxinfo.socialInsuranceNumber:''}}</td>
                                      </tr>
                                      <tr>
                                        <td>Occupation</td>
                                        <td>{{ taxinfo?.occupation}}</td>
                                      </tr>
                                      <tr>
                                        <td>Do you wish to provide information to Elections Canada?</td>
                                        <td>{{taxinfo?.doYouWishToProvideInformationElectionCanada?'Yes':'No' }}</td>
                                      </tr>
                                      <tr>
                                        <td>Is client claiming disablity amount ?</td>
                                        <td>{{taxinfo?.disabilityAmount?'Yes':'No'}}</td>
                                      </tr>

                                      <tr>
                                        <td>Does CRA have a vaild T2201 on file ?</td>
                                        <td>{{taxinfo?.doesCraHaveValidT2201?'Yes':'No' }}</td>
                                      </tr>

                                      <tr>
                                        <td>Does the T2201 have a expire date ?</td>
                                        <td>{{taxinfo?.doesThe2201HaveExpiryDate?'Yes':'No'}}</td>
                                      </tr>

                                      <tr>
                                        <td>T2201 Expiry Date</td>
                                        <td>{{ taxinfo?.t2201ExpiryDate ? taxinfo.t2201ExpiryDate:'--' }}</td>
                                      </tr>

                                    </tbody>
                                  </table>
                                </div>
                              
                            </div>
                          </div>
                        </div>
                       
                      </div>
                    </div>
                  </div>

                  <div class="card">
                    <div class="card-header" id="headingOne1">
                      <section class="mb-0 mt-0">
                        <div role="menu" class="collapsed" data-toggle="collapse" data-target="#defaultAccordionOne"
                          aria-expanded="true" aria-controls="defaultAccordionOne">
                          Mailing Information <div class="icons"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                              height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down">
                              <polyline points="6 9 12 15 18 9"></polyline>
                            </svg></div>
                        </div>
                      </section>
                    </div>
                    <div id="defaultAccordionOne" class="collapse" aria-labelledby="headingOne1"
                      data-parent="#toggleAccordion">
                      <div class="card-body">
                        <div class="card-body">

                          <div class="row layout-top-spacing">
                            <div id="tableSimple" class="col-lg-12 col-12 layout-spacing">
                              <div class="statbox widget box box-shadow">
  
                               
                                  <div class="table-responsive">
                                    <table class="table table-bordered mb-4">
  
                                      <tbody>
                                        <tr>
                                          <td>Email Address</td>
                                          <td>{{ma?.email?ma.email:''}}</td>
                                        </tr>
                                        <tr>
                                          <td>Phone No</td>
                                          <td>{{ma?.mobile?ma.mobile:''}}</td>
                                        </tr>
                                        <tr>
                                          <td>Country</td>
                                          <td>{{ma?.country?ma.country:'--'}}</td>
                                        </tr>
                                        <tr>
                                          <td>Street</td>
                                          <td>{{ ma?.street?ma.street:'--' }}</td>
                                        </tr>
                                        <tr>
                                          <td>Apartment</td>
                                          <td>{{taxinfo?.apartment?taxinfo.apartment:'--'}}</td>
                                        </tr>
                                        <tr>
                                          <td>Postal Code</td>
                                          <td>{{ ma?.postalCode}}</td>
                                        </tr>
                                        <tr>
                                          <td>PO Box</td>
                                          <td>{{ma?.poBox?ma.poBox:'--'}}</td>
                                        </tr>
                                        <tr>
                                          <td>RR</td>
                                          <td>{{ ma?.rr ? ma.rr:'--' }}</td>
                                        </tr>
  
                                        <tr>
                                          <td>City</td>
                                          <td>{{ma?.cityTownPostoffice?ma.cityTownPostoffice:'--'}}</td>
                                        </tr>
  
                                        <tr>
                                          <td>Province/Territory</td>
                                          <td>{{ ma?.province?ma.province:'--' }}</td>
                                        </tr>
  
                                        <tr>
                                          <td>Residence Status</td>
                                          <td>{{ma?.residencyStatus?ma.residencyStatus:'--'}}</td>
                                        </tr>

                                        <tr>
                                          <td>Property Tax/Rent amount paid</td>
                                          <td>{{ma?.amountPaidForYear?ma.amountPaidForYear:'--' }}</td>
                                        </tr>
                                        <tr>
                                          <td>Type</td>
                                          <td>{{ma?.ONBENType?ma.ONBENType:'--'}}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card">
                    <div class="card-header" id="headingThree1">
                      <section class="mb-0 mt-0">
                        <div role="menu" class="collapsed" data-toggle="collapse" data-target="#defaultAccordionThree"
                          aria-expanded="false" aria-controls="defaultAccordionThree">
                          Spouse Information <div class="icons"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                              height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down">
                              <polyline points="6 9 12 15 18 9"></polyline>
                            </svg></div>
                        </div>
                      </section>
                    </div>
                    <div id="defaultAccordionThree" class="collapse" aria-labelledby="headingThree1"
                      data-parent="#toggleAccordion">
                      <div class="card-body">

                        
                        <div class="row layout-top-spacing">
                          <div id="tableSimple" class="col-lg-12 col-12 layout-spacing">
                            <div class="statbox widget box box-shadow">

                             
                                <div class="table-responsive">
                                  <table class="table table-bordered mb-4">

                                    <tbody>
                                      <tr>
                                        <td>Name</td>
                                        <td>{{si?.suffix}} {{si?.firstName}} {{si?.initial}} {{si?.lastName}}</td>
                                      </tr>
                                      <tr>
                                        <td>DOB</td>
                                        <td>{{si?.dob}}</td>
                                      </tr>
                                      <tr>
                                        <td>Gender</td>
                                        <td>{{ si?.gender?si.gender:'--' }}</td>
                                      </tr>
                                      <tr>
                                        <td>Email Address</td>
                                        <td>{{si?.email?si.email:'--'}}</td>
                                      </tr>
                                      <tr>
                                        <td>Phone No</td>
                                        <td>{{si?.mobile?si.mobile:'--'}}</td>
                                      </tr>
                                      <tr>
                                        <td>SIN</td>
                                        <td>{{si?.socialInsuranceNumber?si.socialInsuranceNumber:'--'}}</td>
                                      </tr>
                                      <tr>
                                        <td>Country</td>
                                        <td>{{si?.country?si.country:'--'}}</td>
                                      </tr>
                                      <tr>
                                        <td>Street</td>
                                        <td>{{ si?.street?si.street:'--' }}</td>
                                      </tr>
                                      <tr>
                                        <td>Apartment</td>
                                        <td>{{taxinfo?.apartment?taxinfo.apartment:'--'}}</td>
                                      </tr>
                                      <tr>
                                        <td>Postal Code</td>
                                        <td>{{ si?.postalCode}}</td>
                                      </tr>
                                      <tr>
                                        <td>PO Box</td>
                                        <td>{{si?.poBox?si.poBox:'--'}}</td>
                                      </tr>
                                      <tr>
                                        <td>RR</td>
                                        <td>{{ si?.rr ? si.rr:'--' }}</td>
                                      </tr>

                                      <tr>
                                        <td>City</td>
                                        <td>{{si?.cityTownPostoffice?si.cityTownPostoffice:'--'}}</td>
                                      </tr>

                                      <tr>
                                        <td>Province/Territory</td>
                                        <td>{{ si?.province?si.province:'--' }}</td>
                                      </tr>

                                      <tr>
                                        <td>Residence Status</td>
                                        <td>{{si?.residencyStatus?si.residencyStatus:'--'}}</td>
                                      </tr>

                                      <tr>
                                        <td>Spouse net income</td>
                                        <td>{{si?.spouseNetIncome?si.spouseNetIncome:'--'}}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              
                            </div>
                          </div>
                        </div>
                      

                      </div>
                    </div>
                  </div>

                  <div class="card">
                    <div class="card-header" id="headingFour1">
                      <section class="mb-0 mt-0">
                        <div role="menu" class="collapsed" data-toggle="collapse" data-target="#defaultAccordionFour"
                          aria-expanded="false" aria-controls="defaultAccordionFour">
                          Dependent Information <div class="icons"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                              height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down">
                              <polyline points="6 9 12 15 18 9"></polyline>
                            </svg></div>
                        </div>
                      </section>
                    </div>
                    <div id="defaultAccordionFour" class="collapse" aria-labelledby="headingFour1"
                      data-parent="#toggleAccordion">
                      <div class="card-body">
                        
                        <div  *ngFor="let d of dp">
                          <div class="mt-2"> <label>Name - {{d.suffix}} {{d.firstName}}
                              {{d.initial}}{{d.lastName}}</label></div>
                          <div class="mt-2"><label>Dob -{{d.dob}}</label></div>
                          <div class="mt-2"> <label>Relation - {{ d?.relationship?d.relationship:'' }}</label></div>
                          <div class="mt-2"> <label>SIN - {{ d?.socialInsuranceNumber != 0 ?d.socialInsuranceNumber:'N/A'
                              }}</label>
                            </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



  <div id="content" class="main-content" *ngIf="view2">
    <div class="layout-px-spacing">
      <div class="page-header">
        <div class="page-title">
          <h3>View Tax Summary</h3>
        </div>
        <div class="page-title" style="float: right;cursor: pointer;color: white; font-size: 1rem">
          <h3 routerLink="/manage-ticket"><< Back</h3>
        </div>
      </div>
      <div class="row" id="cancel-row">
        <div class="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
          <div class="widget-content widget-content-area br-6">
            <div class="table-responsive mb-4 mt-4">
              <div id="zero-config_wrapper" class="dataTables_wrapper container-fluid dt-bootstrap4">
                <div id="toggleAccordion">
                  <div class="card">
                    <div class="card-header" id="headingTwo1">
                      <section class="mb-0 mt-0">
                        <div role="menu" class="" data-toggle="collapse" data-target="#defaultAccordionTwo"
                          aria-expanded="false" aria-controls="defaultAccordionTwo">
                          Summary <div class="icons"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down">
                              <polyline points="6 9 12 15 18 9"></polyline>
                            </svg></div>
                        </div>
                      </section>
                    </div>
                    <div id="defaultAccordionTwo" class="collapse show" aria-labelledby="headingTwo1"
                      data-parent="#toggleAccordion">
                      <div class="card-body">
                        <div class="row layout-top-spacing">
                          <div id="tableSimple" class="col-lg-12 col-12 layout-spacing">
                            <div class="statbox widget box box-shadow">

                              <div class="widget-content widget-content-area">
                                <div class="table-responsive">
                                  <table class="table table-bordered mb-4">

                                    <tbody>
                                      <tr>
                                        <td>Total income</td>
                                        <td>${{sum?.totalIncome ? sum.totalIncome : '0.00'}}</td>
                                      </tr>
                                      <tr>
                                        <td>Net income</td>
                                        <td>${{ sum?.netIncome?sum.netIncome:'0.00' }}</td>
                                      </tr>
                                      <tr>
                                        <td>Taxable income</td>
                                        <td>${{sum?.taxableIncome?sum.taxableIncome:'0.00'}}</td>
                                      </tr>
                                      <tr>
                                        <td>Total Credits</td>
                                        <td>${{sum?.totalCredits?sum.totalCredits:'0.00'}}</td>
                                      </tr>
                                      <tr>
                                        <td>Total payable</td>
                                        <td>${{sum?.totalPayble?sum.totalPayble:'0.00'}}</td>
                                      </tr>
                                      <tr>
                                        <td>Total income tax deducted</td>
                                        <td>${{sum?.totalIncomeTaxDeducted?sum.totalIncomeTaxDeducted:'0.00'}}</td>
                                      </tr>
                                      <tr>
                                        <td>Balance owing</td>
                                        <td>${{ sum?.balanceOwing?sum.balanceOwing:'0.00' }}</td>
                                      </tr>
                                      <tr>
                                        <td>Refund</td>
                                        <td>${{sum?.refund ? sum.refund : '0.00'}}</td>
                                      </tr>

                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END MAIN CONTAINER -->