<app-header></app-header>

<!--  BEGIN MAIN CONTAINER  -->
<div class="main-container sidebar-closed sbar-open" id="container">

    <div class="overlay"></div>
    <div class="cs-overlay"></div>
    <div class="search-overlay"></div>

    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">
        <app-navigation></app-navigation>
    </div>
    <!--  END SIDEBAR  -->

    <!--  BEGIN CONTENT AREA  -->
    <div id="content" class="main-content">
        <div class="layout-px-spacing">

            <div class="page-header">
                <div class="page-title">
                    <h3>Reporting</h3>
                </div>

            </div>

            <div class="row mt-4" id="cancel-row">

                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 layout-spacing">
                    <div class="widget widget-account-invoice-two">
                        <div class="widget-content">
                            <div class="account-box account" routerLink="/dashboard" style="cursor: pointer;">
                                <div class="info">
                                    <h5>View All Users</h5>
                                </div>
                                <div class="acc-action">
                                    <div class="">
                                        <a>{{notificationList?notificationList.userCount:''}}</a>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 layout-spacing">
                    <div class="widget widget-account-invoice-two">
                        <div class="widget-content">
                            <div class="account-box account">
                                <div class="info">
                                    <h5>Number of T1 Taxes Prepaid</h5>
                                   
                                </div>
                                <div class="acc-action">
                                    <div class="">
                                        <a>{{notificationList?notificationList.t1FormCout:''}}</a>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 layout-spacing">
                    <div class="widget widget-account-invoice-two">
                        <div class="widget-content">
                            <div class="account-box account">
                                <div class="info">
                                    <h5>Number of Business Taxes Prepaid</h5>
                                    <!-- <p class="inv-balance">$10,344</p> -->
                                </div>
                                <div class="acc-action">
                                    <div class="">
                                        <a>{{notificationList?notificationList.businessCount:''}}</a>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                

                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 layout-spacing">
                    <div class="widget widget-account-invoice-two">
                        <div class="widget-content">
                            <div class="account-box account">
                                <div class="info">
                                    <h5>Total Revenue</h5>
                                    <!-- <p class="inv-balance">$10,344</p> -->
                                </div>
                                <div class="acc-action">
                                    <div class="">
                                        <a>${{notificationList?notificationList.totalRevenue:''}}</a>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 layout-spacing">
                    <div class="widget widget-account-invoice-two">
                        <div class="widget-content">
                            <div class="account-box account">
                                <div class="info">
                                    <h5>Number of Service Requests</h5>
                                </div>
                                <div class="acc-action">
                                    <div class="">
                                        <a>{{ notificationList ? notificationList.serviceRequestCount : ''}}</a>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 layout-spacing">
                    <div class="widget widget-account-invoice-two">
                        <div class="widget-content">
                            <div class="account-box account">
                                <div class="info">
                                    <h5 class="">Number of Financial Planner and Insurance Agents</h5>
                                </div>
                                <div class="acc-action" style="margin-top: -20px;">
                                    <div class="">
                                        <a>{{notificationList?notificationList.agentCount:''}}</a>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
<!--       
   
        <div class="row layout-top-spacing" #target>

            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                <div class="widget widget-one">
                    <div class="widget-heading">
                        <h6 class="">Statistics</h6>
                    </div>
                    <div class="w-chart">
                        <div class="w-chart-section">
                            <div class="w-detail">
                                <p class="w-title">Total Users</p>
                                <p class="w-stats">{{totalUser}}</p>
                            </div>
                            <div class="w-chart-render-one">
                                <div id="total-users"></div>
                            </div>
                        </div>

                        <div class="w-chart-section">
                            <div class="w-detail">
                                <p class="w-title">Total Cataloge</p>
                                <p class="w-stats">{{totalcatalog}}</p>
                            </div>
                            <div class="w-chart-render-one">
                                <div id="paid-visits"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

          
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                <div class="widget widget-chart-three">
                    <div class="widget-heading">
                        <div class="">
                            <h5 class="">Last 7 Days Users/Cataloge</h5>
                        </div>

                        <div class="dropdown  custom-dropdown">
                            <a class="dropdown-toggle" href="#" role="button" id="uniqueVisitors" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-horizontal"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg>
                            </a>

                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="uniqueVisitors">
                                <a class="dropdown-item" href="javascript:void(0);">View</a>
                                <a class="dropdown-item" href="javascript:void(0);">Update</a>
                                <a class="dropdown-item" href="javascript:void(0);">Download</a>
                            </div>
                        </div>
                    </div>

                    <div class="widget-content">
                        <div id="uniqueVisits">
                            
                        </div>
                    </div>
                </div>
            </div>

          
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                <div class="row widget-statistic">
                             
                </div>
            </div>

           
        </div> -->
</div>
<!-- END MAIN CONTAINER -->

</div>
<div class="modal fade bd-example-modal-lg" id="createUserModal" tabindex="-1" role="dialog"
    aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="myLargeModalLabel">Add Notification</h5>
                <button type="button" class="close" (click)="dismissModal()" aria-label="Close">
                    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" class="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                </button>
            </div>
            <form [formGroup]="notificationForm" (ngSubmit)="onSubmit()">
                <div class="modal-body">

                    <div class="form-row mb-4">
                        <div class="col">
                            <textarea class="form-control" formControlName="message" placeholder="Message"
                                [ngClass]="{ 'is-invalid': submitted && f.message.errors }"></textarea>
                            <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                                <div *ngIf="f.message.errors.required">Message is required</div>
                            </div>
                        </div>

                    </div>

                </div>
                <div class="modal-footer">
                    <button class="btn" type="button" (click)="dismissModal()"><i class="flaticon-cancel-12"></i>
                        Discard</button>
                    <button type="submit" class="btn btn-primary">Save</button>
                </div>
            </form>
        </div>
    </div>
    
    