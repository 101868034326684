import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '../models/user';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(username, password,userType) {

    //    console.log("chand",username + "==" + password)
        return this.http.post<any>(`${environment.apiUrl}user/login`, { username, password,userType })
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                // console.log("=========user.result", user)

                if (user.status == 200) {
                    localStorage.setItem('currentUser', JSON.stringify(user.output));
                }
                if (user.output.length == 0) {
                    this.currentUserSubject.next(null);
                } else {
                    this.currentUserSubject.next(user.output);
                }
                return user;
            }));
    }

    logout(userId) {
        // remove user from local storage and set current user to null
        //this.currentUserSubject.next(null);
        return this.http.post<any>(`${environment.apiUrl}user/logout`, {userId:userId})
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                // console.log("=========user.result", user.result)
                localStorage.removeItem('currentUser');      
                this.currentUserSubject.next(null);
               
                return user;
            }));
    }
}
