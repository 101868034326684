import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgApexchartsModule } from "ng-apexcharts";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './modules/login/login.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { HeaderComponent } from './modules/header/header.component';
import { FooterComponent } from './modules/footer/footer.component';
import { NavigationComponent } from './modules/navigation/navigation.component';
import { UsersComponent } from './modules/users/users.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AlertComponent } from './modules/alert/alert.component';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { fakeBackendProvider } from './helpers/fake-backend';
import { NotificationComponent } from './modules/notification/notification.component';
import { BusinessServicesComponent } from './modules/business-services/business-services.component';
import { AccountsProfileComponent } from './modules/accounts-profile/accounts-profile.component';
import { FinancialPlannerComponent } from './modules/financial-planner/financial-planner.component';
import { TaxRequestsComponent } from './modules/tax-requests/tax-requests.component';
import { ViewTaxComponent } from './modules/view-tax/view-tax.component';
import { BusinessTaxComponent } from './modules/business-tax/business-tax.component';
import { ReportingComponent } from './modules/reporting/reporting.component';
import { ManageTicketsComponent } from './modules/manage-tickets/manage-tickets.component';
import { ViewTicketComponent } from './modules/view-ticket/view-ticket.component';
import { AssignInsurance } from './modules/assign-insurance/assign-insurance.component';
import { ManageRrspComponent } from './modules/manage-rrsp/manage-rrsp.component';
import { ManageInsuranceComponent } from './modules/manage-insurance/manage-insurance.component';
import { AssignRrsp } from './modules/assign-rrsp/assign-rrsp.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    HeaderComponent,
    FooterComponent,
    NavigationComponent,
    UsersComponent,
    AlertComponent,
    NotificationComponent,
    BusinessServicesComponent,
    AccountsProfileComponent,
    FinancialPlannerComponent,
    TaxRequestsComponent,
    ViewTaxComponent,
    BusinessTaxComponent,
    ReportingComponent,
    ManageTicketsComponent,
    ViewTicketComponent,
    AssignRrsp,
    ManageInsuranceComponent,
    ManageRrspComponent,
    AssignInsurance

  ],
  imports: [
    BrowserModule,
    NgApexchartsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
