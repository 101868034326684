import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray,AbstractControl } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpRequest, HttpEventType } from "@angular/common/http";
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user.service'
import { AlertService } from '../../services/alert.service';
declare var $: any
declare var select2: any
declare var App: any
declare var swal: any
declare var document: any
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  notificationForm: FormGroup;
  fname: string;
  lname: string;
  username: string;
  password: string;
  cpassword: string;
  email: string;
  mobile: string;
  company: string;
  userType: number;
  submitted: boolean = false;
  totalRows: number = 0;
  notificationList2: any;
  userAlllist:any;
  perpage: number = 10
  numOfPage: number = 0
  page = 1;
  form:any;
  getmessagedata:any;
  sort: string = 'desc'
  search: string = ''
  listType: string = 'user'
  inputtext: any = '';
  id: any;
 
  constructor(private formBuilder: FormBuilder,
    private http: HttpClient, private router: Router, private userService: UserService, private alertService: AlertService,
  ) {
    this.form = this.formBuilder.group({
      checkArray: this.formBuilder.array([]),
      id:['']
    })
    
   }

  ngOnInit() {

    this.loadStyle('datatables', 'assets/plugins/table/datatable/datatables.css')
    this.loadStyle('dt-global_style', 'assets/plugins/table/datatable/dt-global_style.css')
    this.loadStyle('scrollspyNav', 'assets/css/scrollspyNav.css')
    this.loadStyle('custom-modal', 'assets/css/components/custom-modal.css')
    this.loadStyle('animate', 'assets/plugins/animate/animate.css')
    this.loadStyle('structure', 'assets/css/structure.css')
    this.notificationForm = this.formBuilder.group({
      title: ['', Validators.required],
       url: ['', Validators.required],
      notificationText: ['', Validators.required],
    });
    $("#checkAll").change(function () {
      $("input:checkbox").prop('checked', $(this).prop("checked"));
  });
   this.getNotifyList()
  }

  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
       //return false;
      control.setValue('');
      //return false;
    }
    return true;
  }

  get f() { return this.notificationForm.controls; }

  loadStyle(idS: String, styleName: string) {
    // alert(styleName);
    const head = document.getElementsByTagName('head')[0];
    // alert('hi');
    let themeLink = document.getElementById(
      `${idS}`
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleName;
    } else {
      const style = document.createElement('link');
      style.id = `${idS}`;
      style.rel = 'stylesheet';
      style.href = `${styleName}`;

      head.appendChild(style);
    }
  }

  onSubmit() {

    this.submitted = true;
    //this.alertService.clear();
    if (this.notificationForm.invalid) {
      return false;
    }
 //  let postData = { message: this.notificationForm.value.message.trim() }
   let postData =  {"title":this.notificationForm.value.title,"url":this.notificationForm.value.url,"text":this.notificationForm.value.notificationText}
    this.userService.saveNotification(postData).subscribe(succ => {

      if (succ.status) {
        $("#createUserModal").modal('hide');
        this.notificationForm.reset()
        this.submitted = false;
        this.getNotifyList()  
        swal({
          title: succ.message,
          padding: '2em'
        })
      } else {
        swal({
          title: succ.message,
          padding: '2em'
        })
      }
    })
  }

  ngAfterViewInit() {
    if (document.getElementById("load_screen") != null) {
      document.getElementById("load_screen").style.display = "none"
    }
    App.init();

  }

  deleteUser(id) {

    let objt = swal({
      title: 'Are you sure you want to delete?',
      confirmButtonText: 'YES',
      cancelButtonText: 'NO',
      showCancelButton: true,
      showCloseButton: true,
      padding: '2em',
      //target: document.getElementById('rtl-container')
    }).then((value) => {
      if (value.value) {
        this.userService.deleteNotification(id).subscribe(succ => {
          if (succ.status) {
            swal({
              title: succ.message,
              padding: '2em'
            })
            this.getNotifyList()
          } else {
            swal({
              title: succ.message,
              padding: '2em'
            })
          }
        })
      }
    })
  }




  getuserlist(id){

    this.id = id    
   this.userService.getNotificationbyid(id).subscribe(list => {
     if (list.status) {
      //  this.totalRows = list.count;
      //  this.numOfPage = Math.ceil(this.totalRows / this.perpage)

       this.getmessagedata = list.output

       if(this.getmessagedata == 0){
         swal({
           title: "No Results Found",
           padding: '2em'
         })
       }
     }
   })
    this.userService.getListAllUserlist((this.page - 1), this.perpage = 1000000000000000, this.sort, this.listType, this.search).subscribe(list => {
      if (list.status==200) {
        // this.totalRows = list.count;
        // this.numOfPage = Math.ceil(this.totalRows / this.perpage)
      this.userAlllist = list.output.list
       if(this.userAlllist == 0){
          swal({
            title: "No Results Found",
            padding: '2em'
          })
        }
      }
    })

    $("#getnotificationuserlist").modal('show');

  }


 searchuserdata(event) {
    let datasuggested:any ={ "search":event.target.value};
    if(datasuggested !=''){
    this.userService.getusersearchdata(datasuggested).subscribe(list => {
      if (list.status==200) {
        this.userAlllist = list.output
        if(list.output.length <= 0){
          swal({
            title: "No Results Found",
            padding: '2em'
          })
        }
      }
    })
  }
  }

  text(event){  
    this.inputtext = event.target.value
  }

  notificationsearch() {

    if(this.inputtext == 'errr'){
      this.inputtext = ''
    }
    
    let datasuggested:any ={ "search":this.inputtext};
   
    this.userService.notificationsearch(datasuggested).subscribe(list => {
      if (list.status==200) {
        this.notificationList2 = list.output
        if(list.output.length <= 0){
          swal({
            title: "No Results Found",
            padding: '2em'
          })
        }
      }
    })
  
  }

  
  
  
  getNotifyList() {
    
    this.userService.getNotificationList((this.page), this.perpage, this.sort, this.listType, this.search).subscribe(list => {
      if (list.status) {
        this.totalRows = list.output.resultCount;
        this.numOfPage = Math.ceil(this.totalRows / this.perpage)

        this.notificationList2 = list.output.list

        if(this.notificationList2 == 0){
          swal({
            title: "No Results Found",
            padding: '2em'
          })
        }
      }
    })



  }

  sendpushnotification() {
    if(this.form.value.checkArray.length>0){  
    
      this.form.value.id = this.id

       this.form.value.checkArray = [...new Set(this.form.value.checkArray)];

    this.userService.sendnotification(this.form.value).subscribe(list => {
       if (list.status) {
        swal({
          title: "Notification send successfully",
          padding: '2em'
        })
    
        $('#getnotificationuserlist').modal('hide')
       }
    })
  }
  else{
    swal({
      title: "Please Select User",
      padding: '2em'
    })
   }
  }
  

  onCheckboxChange(e) {
    const checkArray: FormArray = this.form.get('checkArray') as FormArray;
  
    if (e.target.checked) {
      checkArray.push(new FormControl(e.target.value));
    } else {
      let i: number = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value == e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
   
  }

  checkUncheckAll(e) {
    const checkArray: FormArray = this.form.get('checkArray') as FormArray;
   
    if (e.target.checked) {
      for (var i = 0; i < this.userAlllist.length; i++) {
      checkArray.push(new FormControl(this.userAlllist[i]._id));
    }
  }
   else {
     while ( checkArray.length !== 0) {
      checkArray.removeAt(0)
     }
   }
  }

 

  changePerPageVal(e) {
    this.perpage = e.target.value
    this.page = 1
    this.getNotifyList()
  }

  pagination(val: any) {
    //alert(val)
    if (val == 'prev') {
      this.page = this.page - 1
    } else if (val == 'next') {
      this.page = this.page + 1
    } else {
      this.page = parseInt(val)
    }
    if (this.page < 1) {
      this.page = 1
    }
    if (this.page <= this.numOfPage) {
      this.getNotifyList()
    }
  }

  sortList() {
    if (this.sort == 'desc') {
      this.sort = 'asc'
    } else if (this.sort == 'asc') {
      this.sort = 'desc'
    }
    this.getNotifyList()
  }

  searchList() {
    this.search = document.getElementById('search').value
    this.page = 1
    this.getNotifyList()
  }

  createUser() {
    this.notificationForm.reset();
    $(".bd-example-modal-lg").modal('show')
  }



  dismissModal() {
    this.submitted = false;
    $(".bd-example-modal-lg").modal('hide')
  }

}

