import { Component, OnInit, ViewChild } from '@angular/core';

import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { HttpClient } from "@angular/common/http";
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service'
import { AlertService } from '../../services/alert.service';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexTitleSubtitle,
  ApexXAxis,
  ApexFill
} from "ng-apexcharts";
declare var $: any
declare var select2: any
declare var App: any
declare var swal: any
declare var document: any
declare var App: any;
declare var document: any;
declare var Apex: any;
declare var ApexCharts: any;
declare var PerfectScrollbar: any;


@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.scss']
})
export class ReportingComponent implements OnInit {
  totalUser: Number = 0;
  totalcatalog: Number = 0;
  userspark: any; //  =[21, 9, 36, 12, 44, 25, 59, 41, 66, 25];
  catalogSpark:any; // = [21, 9, 36, 12, 44, 25, 59, 41, 66, 25];
  yAxis:any 
  notificationForm: FormGroup;
  fname: string;
  lname: string;
  username: string;
  password: string;
  cpassword: string;
  email: string;
  mobile: string;
  company: string;
  userType: number;
  submitted: boolean = false;
  totalRows: number = 0;
  notificationList: any;

  perpage: number = 10
  numOfPage: number = 0
  page = 1;

  sort: string = 'desc'
  search: string = ''
  listType: string = 'user'

  constructor(private formBuilder: FormBuilder,
    private userService: UserService,
  ) {

   
   }

  ngOnInit() {

    this.loadStyle('datatables', 'assets/plugins/table/datatable/datatables.css')
    this.loadStyle('dt-global_style', 'assets/plugins/table/datatable/dt-global_style.css')
    this.loadStyle('scrollspyNav', 'assets/css/scrollspyNav.css')
    this.loadStyle('custom-modal', 'assets/css/components/custom-modal.css')
    this.loadStyle('animate', 'assets/plugins/animate/animate.css')
    this.loadStyle('structure', 'assets/css/structure.css')
    this.loadStyle('datatables', 'assets/plugins/table/datatable/datatables.css')
    this.loadStyle('dt-global_style', 'assets/plugins/table/datatable/dt-global_style.css')
    this.loadStyle('scrollspyNav', 'assets/css/scrollspyNav.css')
    this.loadStyle('custom-modal', 'assets/css/components/custom-modal.css')
    this.loadStyle('animate', 'assets/plugins/animate/animate.css')
    this.loadStyle('structure', 'assets/css/structure.css')
    this.loadStyle('apexcharts', 'assets/plugins/apex/apexcharts.css')
    this.loadStyle('dash_1', 'assets/css/dashboard/dash_1.css')
    this.loadStyle('structure', 'assets/css/structure.css')

    this.notificationForm = this.formBuilder.group({
      message: ['',[this.removeSpaces, Validators.required]],
     
    });

this.totalUser = 10;
this.totalcatalog = 35;
this.userspark = [0, 13, 11, 0, 0, 0, 0]
this.catalogSpark =  [0, 13, 11, 0, 0, 0, 0]
this.yAxis =["7d", "6d", "5d", "4d", "3d", "2d", "1d"]
this.graphpScript();  
    // this.userService.getCount('user').subscribe(count => {
    //   this.totalUser = count.result
    // })
    
    // this.userService.getCount('catalog').subscribe(count => {
    //   this.totalcatalog = count.result
    // })
  

    // this.userService.getGgraphData('user').subscribe(data => {
    //   this.userspark = data.result.yAxis      
    //   this.userService.getGgraphData('catalog').subscribe(data => {
    //     this.catalogSpark = data.result.yAxis
    //     this.yAxis = data.result.xAxis
    //     this.graphpScript()  
        
    //   });
         
    // })

    this.getNotifyList()
  }

  graphpScript() {

    try {

      Apex.tooltip = {
        theme: 'dark'
      }

      /*
          ==============================
          |    @Options Charts Script   |
          ==============================
      */

      /*
          ======================================
              Visitor Statistics | Options
          ======================================
      */


      // Total Visits

      var spark1 = {
        chart: {
          id: 'unique-visits',
          group: 'sparks2',
          type: 'line',
          height: 80,
          sparkline: {
            enabled: true
          },
          dropShadow: {
            enabled: true,
            top: 1,
            left: 1,
            blur: 2,
            color: '#e2a03f',
            opacity: 0.7,
          }
        },
        series: [{
          data: [21, 9, 36, 12, 44, 25, 59, 41, 66, 25]
        }],
        stroke: {
          curve: 'smooth',
          width: 2,
        },
        markers: {
          size: 0
        },
        grid: {
          padding: {
            top: 35,
            bottom: 0,
            left: 40
          }
        },
        colors: ['#e2a03f'],
        tooltip: {
          x: {
            show: false
          },
          y: {
            title: {
              formatter: function formatter(val) {
                return '';
              }
            }
          }
        },
        responsive: [{
          breakpoint: 1351,
          options: {
            chart: {
              height: 95,
            },
            grid: {
              padding: {
                top: 35,
                bottom: 0,
                left: 0
              }
            },
          },
        },
        {
          breakpoint: 1200,
          options: {
            chart: {
              height: 80,
            },
            grid: {
              padding: {
                top: 35,
                bottom: 0,
                left: 40
              }
            },
          },
        },
        {
          breakpoint: 576,
          options: {
            chart: {
              height: 95,
            },
            grid: {
              padding: {
                top: 45,
                bottom: 0,
                left: 0
              }
            },
          },
        }

        ]
      }

      // Paid Visits

      var spark2 = {
        chart: {
          id: 'total-users',
          group: 'sparks1',
          type: 'line',
          height: 80,
          sparkline: {
            enabled: true
          },
          dropShadow: {
            enabled: true,
            top: 3,
            left: 1,
            blur: 3,
            color: '#009688',
            opacity: 0.7,
          }
        },
        series: [{
          data:[22, 19, 30, 47, 32, 44, 34, 55, 41, 69]
        }],
        stroke: {
          curve: 'smooth',
          width: 2,
        },
        markers: {
          size: 0
        },
        grid: {
          padding: {
            top: 35,
            bottom: 0,
            left: 40
          }
        },
        colors: ['#009688'],
        tooltip: {
          x: {
            show: false
          },
          y: {
            title: {
              formatter: function formatter(val) {
                return '';
              }
            }
          }
        },
        responsive: [{
          breakpoint: 1351,
          options: {
            chart: {
              height: 95,
            },
            grid: {
              padding: {
                top: 35,
                bottom: 0,
                left: 0
              }
            },
          },
        },
        {
          breakpoint: 1200,
          options: {
            chart: {
              height: 80,
            },
            grid: {
              padding: {
                top: 35,
                bottom: 0,
                left: 40
              }
            },
          },
        },
        {
          breakpoint: 576,
          options: {
            chart: {
              height: 95,
            },
            grid: {
              padding: {
                top: 35,
                bottom: 0,
                left: 0
              }
            },
          },
        }
        ]
      }


      /*
          ===================================
              Unique Visitors | Options
          ===================================
      */

      var d_1options1 = {
        chart: {
          height: 350,
          type: 'bar',
          toolbar: {
            show: true,
          },
          dropShadow: {
            enabled: true,
            top: 1,
            left: 1,
            blur: 1,
            color: '#515365',
            opacity: 0.3,
          }
        },
        colors: ['#5c1ac3', '#ffbb44'],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          fontSize: '14px',
          markers: {
            width: 10,
            height: 10,
          },
          itemMargin: {
            horizontal: 0,
            vertical: 8
          }
        },
        grid: {
          borderColor: '#191e3a',
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        series: [{
          name: 'Users',
          data: [58, 44, 55, 57, 56, 61, 58, 63, 60, 66, 56, 63]
        }, {
          name: 'Catalogue',
          data: [91, 76, 85, 101, 98, 87, 105, 91, 114, 94, 66, 70]
        }],
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'vertical',
            shadeIntensity: 0.3,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 0.8,
            stops: [0, 100]
          }
        },
        tooltip: {
          theme: 'dark',
          y: {
            formatter: function (val) {
              return val
            }
          }
        }
      }

      /*
          ==============================
              Statistics | Options
          ==============================
      */

      // Followers

      var d_1options3 = {
        chart: {
          id: 'sparkline1',
          type: 'area',
          height: 160,
          sparkline: {
            enabled: true
          },
        },
        stroke: {
          curve: 'smooth',
          width: 2,
        },
        series: [{
          name: 'Sales',
          data: [38, 60, 38, 52, 36, 40, 28]
        }],
        labels: ['1', '2', '3', '4', '5', '6', '7'],
        yaxis: {
          min: 0
        },
        colors: ['#1b55e2'],
        tooltip: {
          x: {
            show: false,
          }
        },
        fill: {
          type: "gradient",
          gradient: {
            type: "vertical",
            shadeIntensity: 1,
            inverseColors: !1,
            opacityFrom: .40,
            opacityTo: .05,
            stops: [45, 100]
          }
        },
      }

      // Referral

      var d_1options4 = {
        chart: {
          id: 'sparkline1',
          type: 'area',
          height: 160,
          sparkline: {
            enabled: true
          },
        },
        stroke: {
          curve: 'smooth',
          width: 2,
        },
        series: [{
          name: 'Sales',
          data: [60, 28, 52, 38, 40, 36, 38]
        }],
        labels: ['1', '2', '3', '4', '5', '6', '7'],
        yaxis: {
          min: 0
        },
        colors: ['#e7515a'],
        tooltip: {
          x: {
            show: false,
          }
        },
        fill: {
          type: "gradient",
          gradient: {
            type: "vertical",
            shadeIntensity: 1,
            inverseColors: !1,
            opacityFrom: .40,
            opacityTo: .05,
            stops: [45, 100]
          }
        },
      }

      // Engagement Rate

      var d_1options5 = {
        chart: {
          id: 'sparkline1',
          type: 'area',
          height: 160,
          sparkline: {
            enabled: true
          },
        },
        stroke: {
          curve: 'smooth',
          width: 2,
        },
        fill: {
          opacity: 1,
        },
        series: [{
          name: 'Sales',
          data: [28, 50, 36, 60, 38, 52, 38]
        }],
        labels: ['1', '2', '3', '4', '5', '6', '7'],
        yaxis: {
          min: 0
        },
        colors: ['#009688'],
        tooltip: {
          x: {
            show: false,
          }
        },
        /*fill: {
            type:"gradient",
            gradient: {
                type: "vertical",
                shadeIntensity: 1,
                inverseColors: !1,
                opacityFrom: .40,
                opacityTo: .05,
                stops: [45, 100]
            }
        },*/
      }




      /*
          ==============================
          |    @Render Charts Script    |
          ==============================
      */


      /*
          ======================================
              Visitor Statistics | Script
          ======================================
      */

      // Total Visits
      var d_1C_1 = new ApexCharts(document.querySelector("#total-users"), spark1);
      d_1C_1.render();

      // Paid Visits
      var d_1C_2 = new ApexCharts(document.querySelector("#paid-visits"), spark2);
      d_1C_2.render();

      /*
          ===================================
              Unique Visitors | Script
          ===================================
      */

      var d_1C_3 = new ApexCharts(
        document.querySelector("#uniqueVisits"),
        d_1options1
      );
      d_1C_3.render();

      /*
          ==============================
              Statistics | Script
          ==============================
      */


      // Followers

      var d_1C_5 = new ApexCharts(document.querySelector("#hybrid_followers"), d_1options3);
      d_1C_5.render()

      // Referral

      var d_1C_6 = new ApexCharts(document.querySelector("#hybrid_followers1"), d_1options4);
      d_1C_6.render()

      // Engagement Rate

      var d_1C_7 = new ApexCharts(document.querySelector("#hybrid_followers3"), d_1options5);
      d_1C_7.render()



      /*
          =============================================
              Perfect Scrollbar | Notifications
          =============================================
      */
      const ps = new PerfectScrollbar(document.querySelector('.mt-container'));


    } catch (e) {
      console.log(e);
    }}
  

  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
       //return false;
      control.setValue('');
      //return false;
    }
    return true;
  }

  get f() { return this.notificationForm.controls; }

  loadStyle(idS: String, styleName: string) {
    // alert(styleName);
    const head = document.getElementsByTagName('head')[0];
    // alert('hi');
    let themeLink = document.getElementById(
      `${idS}`
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleName;
    } else {
      const style = document.createElement('link');
      style.id = `${idS}`;
      style.rel = 'stylesheet';
      style.href = `${styleName}`;

      head.appendChild(style);
    }
  }

  onSubmit() {

    this.submitted = true;
    //this.alertService.clear();
    if (this.notificationForm.invalid) {
      return false;
    }

    
    let postData = { message: this.notificationForm.value.message.trim() }
    this.userService.createNotification(postData).subscribe(succ => {
      if (succ.status) {
        $("#createUserModal").modal('hide');
        this.notificationForm.reset()
        this.submitted = false;
    
        this.getNotifyList()  
        swal({
          title: succ.message,
          padding: '2em'
        })
      } else {
        swal({
          title: succ.message,
          padding: '2em'
        })
      }

    })
  }

  ngAfterViewInit() {
    if (document.getElementById("load_screen") != null) {
      document.getElementById("load_screen").style.display = "none"
    }
    App.init();

  }

  deleteUser(id) {

  }


  getNotifyList() {
    this.userService.getNotificationListreport((this.page - 1), this.perpage, this.sort, this.listType, this.search).subscribe(list => {
      if (list.status) {
        this.totalRows = list.count;
        this.numOfPage = Math.ceil(this.totalRows / this.perpage)

        this.notificationList = list.output

        if(this.notificationList == 0){
          swal({
            title: "No Results Found",
            padding: '2em'
          })
        }
      }
    })
  }
  changePerPageVal(e) {
    this.perpage = e.target.value
    this.page = 1
    this.getNotifyList()
  }

  pagination(val: any) {
    //alert(val)
    if (val == 'prev') {
      this.page = this.page - 1
    } else if (val == 'next') {
      this.page = this.page + 1
    } else {
      this.page = parseInt(val)
    }
    if (this.page < 1) {
      this.page = 1
    }
    if (this.page <= this.numOfPage) {
      this.getNotifyList()
    }
  }

  sortList() {
    if (this.sort == 'desc') {
      this.sort = 'asc'
    } else if (this.sort == 'asc') {
      this.sort = 'desc'
    }
    this.getNotifyList()
  }

  searchList() {
    this.search = document.getElementById('search').value
    this.page = 1
    this.getNotifyList()
  }

  createUser() {
    this.notificationForm.reset();
    $(".bd-example-modal-lg").modal('show')
  }



  dismissModal() {
    this.submitted = false;
    $(".bd-example-modal-lg").modal('hide')
  }
  scroll(el: HTMLElement) {
    el.scrollIntoView();
}
}


