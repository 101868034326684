import { Component, OnInit } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpRequest, HttpEventType } from "@angular/common/http";
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { AlertService } from '../../services/alert.service';
import { AuthenticationService } from '../../services/authentication.service'
import { $ } from 'protractor';

declare var document: any;
declare  var swal:any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  page: any = 'login'
  loginForm: FormGroup;
  username: string;
  password: string;
  submitted = false;
  loading = false;
  errmessage:any;
  returnUrl: string = '/reporting';
  showPassword: any = false
  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
   
  ) {
    //this.loadStyle('form-2', 'assets/css/authentication/form-2.css')

    if (this.authenticationService.currentUserValue) {
      // alert('hi');
      this.router.navigate(['/']);
    }

  }

  ngOnInit() {

    // loginForm: FormGroup;

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      userType:['admin']
    });


  }

  get f() { return this.loginForm.controls; }


  loadStyle(idS: String, styleName: string) {
    // alert(styleName);
    const head = document.getElementsByTagName('head')[0];
    // alert('hi');
    let themeLink = document.getElementById(
      `${idS}`
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleName;
    } else {
      const style = document.createElement('link');
      style.id = `${idS}`;
      style.rel = 'stylesheet';
      style.href = `${styleName}`;

      head.appendChild(style);
    }
  }

  onSubmit() {

    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    
    // stop here if form is invalid
    if (this.loginForm.invalid) {

      return;
    }
 
    this.loading = true;
   
    this.authenticationService.login(this.f.username.value, this.f.password.value , this.f.userType.value)
      .pipe(first())
      .subscribe(
        data => {
         this.loading = false;
          if (data.status==200) {
           this.router.navigate(['/reporting']);
          } else {
            alert(data.error.message);
             this.errmessage= data.error.message;
              swal({
                title: data.error.message,
                padding: '2em'
              })
            }

        },
        error => {

          console.log(error);
          this.loading = false;
        // this.errmessage= error.message;
          this.router.navigate(['/']);
       }
        );
  }

  showPass() {
    // alert(this.showPassword)
    if (this.showPassword) {
      this.showPassword = false;
    } else {
      this.showPassword = true;
    }
  }
}

