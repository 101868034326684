import { Component, OnInit,AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpRequest, HttpEventType } from "@angular/common/http";
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user.service'
import { AlertService } from '../../services/alert.service';

declare var $: any
declare var select2: any
declare var App: any
declare var swal: any
declare var document: any
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  userProfileForm: FormGroup;
  fname: string;
  lname: string;
  username: string;
  password: string;
  cpassword: string;
  email: string;
  mobile: string;
  company: string;
  userType: number;
  submitted: boolean = false;
  totalRows: number = 0;
  userList: any;

  perpage: number = 10
  numOfPage: number = 0
  page = 1;

  sort: string = 'desc'
  search: string = ''
  listType: string = 'user'
  inputtext: any = '';

  constructor(private formBuilder: FormBuilder,
    private http: HttpClient, private router: Router, private userService: UserService, private alertService: AlertService,
  ) { }

  ngOnInit() {

    this.loadStyle('datatables', 'assets/plugins/table/datatable/datatables.css')
    this.loadStyle('dt-global_style', 'assets/plugins/table/datatable/dt-global_style.css')
    this.loadStyle('scrollspyNav', 'assets/css/scrollspyNav.css')
    this.loadStyle('custom-modal', 'assets/css/components/custom-modal.css')
    this.loadStyle('animate', 'assets/plugins/animate/animate.css')
    this.loadStyle('structure', 'assets/css/structure.css')

    this.userProfileForm = this.formBuilder.group({
      fname: ['', Validators.required],
      lname: ['', Validators.required],
      username: ['', Validators.required],
      password: ['', [Validators.required,Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]],
      cpassword: ['', Validators.required],
      email: ['', Validators.required],
      mobile: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(12)]],
      company: ['', Validators.required],
      userType: ['', Validators.required],
    }, {
      validator: this.MustMatch('password', 'cpassword'),
      validators: this.ValidateEmail('email')
    });

    this.getUserList()
  }

  get f() { return this.userProfileForm.controls; }


  ValidateEmail(controlName: string){

    return (formGroup: FormGroup) => {  
      const control = formGroup.controls[controlName];
      
      if (control.errors ) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value.indexOf('@') == -1 || control.value.indexOf('.') == -1) {
        control.setErrors({ ValidateEmail: true });
      } else {
        control.setErrors(null);
      }
    }

  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {  
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  loadStyle(idS: String, styleName: string) {
    // alert(styleName);
    const head = document.getElementsByTagName('head')[0];
    // alert('hi');
    let themeLink = document.getElementById(
      `${idS}`
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleName;
    } else {
      const style = document.createElement('link');
      style.id = `${idS}`;
      style.rel = 'stylesheet';
      style.href = `${styleName}`;

      head.appendChild(style);
    }
  }

  onSubmit() {
    this.submitted = true;
    
    //this.alertService.clear();
    if (this.userProfileForm.invalid) {
      
      return false;
    }
    
   // console.log(this.userProfileForm.value.fname)
    //this.userService.createUser()

    let postData = { user_type: this.userProfileForm.value.userType, fname: this.userProfileForm.value.fname, lname: this.userProfileForm.value.lname, mobile: this.userProfileForm.value.mobile, username: this.userProfileForm.value.username, password: this.userProfileForm.value.password, device_id: "", firebase_token: "", company_name: this.userProfileForm.value.company, email: this.userProfileForm.value.email }
    this.userService.createUser(postData).subscribe(succ => {
      if (succ.status) {
        $("#createUserModal").modal('hide');
        this.userProfileForm.reset()
        this.submitted = false;
    
        //this.alertService.success(succ.message);
        swal({
          title: succ.message,
          padding: '2em'
        }).then((value) => {
          
        });
      } else {
        swal({
          title: succ.message,
          padding: '2em'
        })
      }

    })
  }

  ngAfterViewInit() {
    if (document.getElementById("load_screen") != null) {
      document.getElementById("load_screen").style.display = "none"
    }
    App.init();

  }

  deleteUser(id) {

    let objt = swal({
      title: 'Are you sure you want to delete?',
      confirmButtonText: 'YES',
      cancelButtonText: 'NO',
      showCancelButton: true,
      showCloseButton: true,
      padding: '2em',
      //target: document.getElementById('rtl-container')
    }).then((value) => {
      if (value.value) {
        this.userService.deleteUser(id).subscribe(succ => {
          if (succ.status) {
            swal({
              title: succ.message,
              padding: '2em'
            })
            this.getUserList()
          } else {
            swal({
              title: succ.message,
              padding: '2em'
            })
          }
        })
      }
    })
  }

  text(event){  
    this.inputtext = event.target.value
  }

  accountsearch(){

    let datasuggested:any ={ "search":this.inputtext};
    this.userService.getusersearchdataforaccountant(datasuggested).subscribe(list => {
      if (list.status==200) {
        this.userList = list.output.list

        if(list.output.length <= 0){
          swal({
            title: "No Results Found",
            padding: '2em'
          })
        }
      }
    })
  }

  getUserList() {     
    this.userService.getListAllUserlist((this.page), Number(this.perpage), this.sort, this.listType, this.search).subscribe(list => {
      if (list.status) {
        this.totalRows = list.output.resultCount;
        this.numOfPage = Math.ceil(this.totalRows / this.perpage)
        this.userList = list.output.list
        if(this.userList == 0){
          swal({
            title: "No Results Found",
            padding: '2em'
          })
        }
      }
    })
  }

  changePerPageVal(e) {
    this.perpage = e.target.value
    this.page = 1
    this.getUserList()
  }

  pagination(val: any) {
    //alert(val)
    if (val == 'prev') {
      this.page = this.page - 1
    } else if (val == 'next') {
      this.page = this.page + 1
    } else {
      this.page = parseInt(val)
    }
    if (this.page < 1) {
      this.page = 1
    }
    if (this.page <= this.numOfPage) {
      this.getUserList()
    }
  }

  sortList() {
    if (this.sort == 'desc') {
      this.sort = 'asc'
    } else if (this.sort == 'asc') {
      this.sort = 'desc'
    }
    this.getUserList()
  }

  searchList() {
    this.search = this.inputtext
    this.page = 1
    this.getUserList()
  }

  createUser() {
    //this.userProfileForm.reset();
    $(".bd-example-modal-lg").modal('show')
  }

  dismissModal() {
    this.submitted = false;
    $(".bd-example-modal-lg").modal('hide')
  }

}
