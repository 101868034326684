<app-header></app-header>

<!--  BEGIN MAIN CONTAINER  -->
<div class="main-container sidebar-closed sbar-open" id="container">

    <div class="overlay"></div>
    <div class="cs-overlay"></div>
    <div class="search-overlay"></div>

    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">
        <app-navigation></app-navigation>
    </div>
    <!--  END SIDEBAR  -->

    <!--  BEGIN CONTENT AREA  -->
    <div id="content" class="main-content">
        <div class="layout-px-spacing">

            <div class="page-header">
                <div class="page-title">
                    <h3>View Business Tax Prepration Requests</h3>
                </div>
              <!--  <button class="btn btn-primary mb-2 " style="float: right;
                margin-top: 21px;" (click)="createUser()">Add User</button>-->
            </div>




            <div class="row" id="cancel-row">

                <div class="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                    <div class="widget-content widget-content-area br-6">
                        <div class="table-responsive mb-4 mt-4">
                            <div id="zero-config_wrapper" class="dataTables_wrapper container-fluid dt-bootstrap4">
                                <div class="row">
                                    <div class="col-sm-12 col-md-6">
                                        <div class="dataTables_length" id="zero-config_length">
                                            <label>Results : <select
                                                    name="zero-config_length" aria-controls="zero-config"
                                                    class="form-control" (change)="changePerPageVal($event)">
                                                    <option value="5" *ngIf="perpage != 5">5</option>
                                                    <option value="5" *ngIf="perpage == 5" selected="selected">5
                                                    </option>
                                                    <option value="10" *ngIf="perpage != 10">10</option>
                                                    <option value="10" *ngIf="perpage == 10" selected="selected">10
                                                    </option>
                                                    <option value="20" *ngIf="perpage != 20">20</option>
                                                    <option value="20" *ngIf="perpage == 20" selected="selected">20
                                                    </option>
                                                    <option value="50" *ngIf="perpage != 50">50</option>
                                                    <option value="50" *ngIf="perpage == 50" selected="selected">50
                                                    </option>
                                                </select>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-6">
                                        <div id="zero-config_filter" class="dataTables_filter"><label><svg
                                                    (click)="searchList()" xmlns="http://www.w3.org/2000/svg" width="24"
                                                    height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-search">
                                                    <circle cx="11" cy="11" r="8"></circle>
                                                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                                </svg><input type="search" id="search" name="search"
                                                    class="form-control" placeholder="Search..."
                                                    aria-controls="zero-config"></label></div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <table id="zero-config" class="table table-hover dataTable" style="width: 100%;"
                                            role="grid" aria-describedby="zero-config_info">
                                            <thead>
                                                <tr role="row">
                                                    <th tabindex="0" aria-controls="zero-config" rowspan="1" colspan="1"
                                                        style="width: 40px;">No.</th>

                                                    <th tabindex="0"
                                                        aria-controls="zero-config" rowspan="1" colspan="1"
                                                        style="width: 100px;">Order Id</th>
                                                    <th tabindex="0" aria-controls="zero-config" rowspan="1" colspan="1"
                                                        style="width: 150px;">Client Name</th>
                                                    <th tabindex="0" aria-controls="zero-config" rowspan="1" colspan="1"
                                                        style="width: 100px;">Financial Year</th>

                                                    <th tabindex="0" aria-controls="zero-config" rowspan="1" colspan="1"
                                                        style="width: 200px;">Client Tax Information</th>
                                                    <th tabindex="0" aria-controls="zero-config" rowspan="1" colspan="1"
                                                        style="width: 150px;">View Tax Information</th>
                                                    <th tabindex="0" aria-controls="zero-config" rowspan="1" colspan="1"
                                                        style="width: 150px;">Prepared By</th>    
                                                    <th tabindex="0" aria-controls="zero-config" rowspan="1" colspan="1"
                                                        style="width: 150px;">Additional Service Requested
                                                    </th>    

                                                    <th tabindex="0" aria-controls="zero-config" rowspan="1" colspan="1"
                                                        style="width: 78px;">name of Service
                                                    </th>    
                                                    
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr role="row">
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>

                                            </tbody>
                                            <!--<tfoot>
                                                <tr>
                                                    <th rowspan="1" colspan="1">Name</th>
                                                    <th rowspan="1" colspan="1">Position</th>
                                                    <th rowspan="1" colspan="1">Office</th>
                                                    <th rowspan="1" colspan="1">Age</th>
                                                    <th rowspan="1" colspan="1">Start date</th>
                                                    <th rowspan="1" colspan="1">Salary</th>
                                                    <th rowspan="1" colspan="1"></th>
                                                </tr>
                                            </tfoot>-->
                                        </table>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md-5">
                                        <div class="dataTables_info" id="zero-config_info" role="status"
                                            aria-live="polite">Showing page {{page}} of {{numOfPage}}</div>
                                    </div>
                                    <div class="col-sm-12 col-md-7">
                                        <div class="dataTables_paginate paging_simple_numbers"
                                            id="zero-config_paginate">
                                            <ul class="pagination">
                                                <li *ngIf="page > 1" class="paginate_button page-item previous"
                                                    id="zero-config_previous"><a (click)="pagination('prev')"
                                                        aria-controls="zero-config" data-dt-idx="0" tabindex="0"
                                                        class="page-link"><svg xmlns="http://www.w3.org/2000/svg"
                                                            width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                            stroke="currentColor" stroke-width="2"
                                                            stroke-linecap="round" stroke-linejoin="round"
                                                            class="feather feather-arrow-left">
                                                            <line x1="19" y1="12" x2="5" y2="12"></line>
                                                            <polyline points="12 19 5 12 12 5"></polyline>
                                                        </svg></a></li>
                                                <li *ngIf="numOfPage >= 1 && page == 1"
                                                    class="paginate_button page-item active"><a (click)="pagination(1)"
                                                        aria-controls="zero-config" data-dt-idx="1" tabindex="0"
                                                        class="page-link">1</a></li>
                                                <li *ngIf="numOfPage >= 1 && page != 1"
                                                    class="paginate_button page-item"><a (click)="pagination(1)"
                                                        aria-controls="zero-config" data-dt-idx="1" tabindex="0"
                                                        class="page-link">1</a></li>


                                                <li *ngIf="numOfPage >= 2 && page == 2"
                                                    class="paginate_button page-item active"><a (click)="pagination(2)"
                                                        aria-controls="zero-config" data-dt-idx="2" tabindex="0"
                                                        class="page-link">2</a></li>
                                                <li *ngIf="numOfPage >= 2 && page != 2"
                                                    class="paginate_button page-item "><a (click)="pagination(2)"
                                                        aria-controls="zero-config" data-dt-idx="2" tabindex="0"
                                                        class="page-link">2</a></li>


                                                <li *ngIf="numOfPage >= 3 && page == 3"
                                                    class="paginate_button page-item active"><a (click)="pagination(3)"
                                                        aria-controls="zero-config" data-dt-idx="3" tabindex="0"
                                                        class="page-link">3</a></li>
                                                <li *ngIf="numOfPage >= 3 && page != 3"
                                                    class="paginate_button page-item "><a (click)="pagination(3)"
                                                        aria-controls="zero-config" data-dt-idx="3" tabindex="0"
                                                        class="page-link">3</a></li>

                                                <li *ngIf="numOfPage >= 4 && page == 4"
                                                    class="paginate_button page-item active"><a (click)="pagination(4)"
                                                        aria-controls="zero-config" data-dt-idx="4" tabindex="0"
                                                        class="page-link">4 </a></li>
                                                <li *ngIf="numOfPage >= 4 && page != 4"
                                                    class="paginate_button page-item "><a (click)="pagination(4)"
                                                        aria-controls="zero-config" data-dt-idx="4" tabindex="0"
                                                        class="page-link">4 </a></li>

                                                <li *ngIf="numOfPage >= 5 && page == 5"
                                                    class="paginate_button page-item active"><a (click)="pagination(5)"
                                                        aria-controls="zero-config" data-dt-idx="5" tabindex="0"
                                                        class="page-link">5</a></li>
                                                <li *ngIf="numOfPage >= 5 && page != 5"
                                                    class="paginate_button page-item "><a (click)="pagination(5)"
                                                        aria-controls="zero-config" data-dt-idx="5" tabindex="0"
                                                        class="page-link">5</a></li>
                                                <li></li>
                                                <li *ngIf="page > 5" class="paginate_button page-item active"><a
                                                        (click)="pagination(page)" aria-controls="zero-config"
                                                        data-dt-idx="5" tabindex="0" class="page-link">{{page}}</a></li>
                                                <li *ngIf="page > 5"></li> 
                                                <li *ngIf="numOfPage > 5  && page < numOfPage"
                                                    class="paginate_button page-item next" id="zero-config_next"><a
                                                        (click)="pagination('next')" aria-controls="zero-config"
                                                        data-dt-idx="6" tabindex="0" class="page-link"><svg
                                                            xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                            viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                            stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round" class="feather feather-arrow-right">
                                                            <line x1="5" y1="12" x2="19" y2="12"></line>
                                                            <polyline points="12 5 19 12 12 19"></polyline>
                                                        </svg></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
    <!--  END CONTENT AREA  -->

</div>
<!-- END MAIN CONTAINER -->


<!--<div class="modal fade bd-example-modal-lg" id="createUserModal" tabindex="-1" role="dialog"
    aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="myLargeModalLabel">Add User</h5>
                <button type="button" class="close" (click)="dismissModal()" aria-label="Close">
                    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" class="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                </button>
            </div>
            <form [formGroup]="userProfileForm" (ngSubmit)="onSubmit()">
                <div class="modal-body">

                    <div class="form-row mb-4">
                        <div class="col">
                            <input type="text" class="form-control" formControlName="fname"
                                oninput="this.value = this.value.replace(/\b\w/g, function(l){ return l.toUpperCase() })"
                                placeholder="First Name" [ngClass]="{ 'is-invalid': submitted && f.fname.errors }" />
                            <div *ngIf="submitted && f.fname.errors" class="invalid-feedback">
                                <div *ngIf="f.fname.errors.required">First Name is required</div>
                            </div>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" formControlName="lname"
                                oninput="this.value = this.value.replace(/\b\w/g, function(l){ return l.toUpperCase() })"
                                placeholder="Last Name" [ngClass]="{ 'is-invalid': submitted && f.lname.errors }" />
                            <div *ngIf="submitted && f.lname.errors" class="invalid-feedback">
                                <div *ngIf="f.lname.errors.required">Last Name is required</div>
                            </div>
                        </div>
                    </div>

                    <div class="form-row mb-4">

                        <div class="col">
                            <input type="text" class="form-control" formControlName="email" placeholder="Email"
                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">Email is required</div>
                                <div *ngIf="f.email.errors.ValidateEmail">Email must be a valid email address</div>
                            </div>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" formControlName="company"
                                oninput="this.value = this.value.replace(/\b\w/g, function(l){ return l.toUpperCase() })"
                                placeholder="Company Name"
                                [ngClass]="{ 'is-invalid': submitted && f.company.errors }" />
                            <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
                                <div *ngIf="f.company.errors.required">Company Name is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row mb-4">
                        <div class="col">
                            <input type="text" class="form-control" formControlName="mobile" placeholder="Mobile Number"
                                [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" />
                            <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                                <div *ngIf="f.mobile.errors.required">Mobile Number is required</div>
                                <div *ngIf="f.mobile.errors.pattern">Please enter valid mobile number.</div>
                                <div
                                    *ngIf="f.mobile.errors.minlength && f.mobile.errors.maxlength &&  !f.mobile.errors.pattern">
                                    Please enter valid mobile number.</div>

                            </div>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" formControlName="username" placeholder="User Name"
                                [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                            <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                <div *ngIf="f.username.errors.required">User Name is required</div>
                            </div>
                        </div>


                    </div>

                    <div class="form-row mb-4">
                        <div class="col">
                            <input type="password" class="form-control" formControlName="password"
                                placeholder="Password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                <div *ngIf="f.password.errors.required">Password is required</div>
                                <div *ngIf="f.password.errors.pattern">Passwords Should be At least 8 characters in
                                    length
                                    Lowercase letters,Uppercase letters,Numbers,Special characters</div>
                            </div>
                        </div>

                        <div class="col">
                            <input type="password" class="form-control" formControlName="cpassword"
                                placeholder="Confirm Password"
                                [ngClass]="{ 'is-invalid': submitted && f.cpassword.errors }" />
                            <div *ngIf="submitted && f.cpassword.errors" class="invalid-feedback">
                                <div *ngIf="f.cpassword.errors.required">Confirm Password is required</div>
                                <div *ngIf="f.cpassword.errors.mustMatch">Passwords must match</div>
                            </div>
                        </div>

                    </div>
                    <div class="form-row mb-4">
                        <div class="col">
                            <select class="form-control" formControlName="userType"
                                [ngClass]="{ 'is-invalid': submitted && f.userType.errors }">
                                <option value="" selected="selected">Select User Type</option>
                                <option value="100">Administrator</option>
                                <option value="101">Team Leader</option>
                                <option value="102">Regular User</option>

                            </select>
                            <div *ngIf="submitted && f.userType.errors" class="invalid-feedback">
                                <div *ngIf="f.userType.errors.required">User Type is required</div>
                            </div>

                        </div>
                    </div>
                    Default Input 



                </div>
                <div class="modal-footer">
                    <button class="btn" type="button" (click)="dismissModal()"><i class="flaticon-cancel-12"></i>
                        Discard</button>
                    <button type="submit" class="btn btn-primary">Save</button>
                </div>
            </form>
        </div>
    </div>
</div>-->