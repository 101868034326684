<div class="form-container outer">
    <div class="form-form">
        <div class="form-form-wrap">
            <div class="form-container">
                <div class="form-content">

                    <h1 class="">Tax Volt</h1>
                    <p class="">Log in to your account to continue.</p>
                    <p class="redcol">{{errmessage}}</p>
                    <form class="text-left" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                        <div class="form">

                            <div id="username-field" class="field-wrapper input">
                                <label for="username">USERNAME</label>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"  style="top: 50px;"
                                    stroke-linejoin="round" class="feather feather-user">
                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                    <circle cx="12" cy="7" r="4"></circle>
                                </svg>
                                <input id="username" name="username" type="text" class="form-control"
                                    placeholder="e.g John_Doe" formControlName="username"
                                    [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                    <div *ngIf="f.username.errors.required">User Name is required</div>
                                </div>
                            </div>

                            <div id="password-field" class="field-wrapper input mb-2">
                                <div class="d-flex justify-content-between">
                                    <label for="password">PASSWORD</label>
                                    <!-- <a href="auth_pass_recovery_boxed.html" class="forgot-pass-link">Forgot Password?</a>-->
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" style="top: 43px;"
                                    stroke-linejoin="round" class="feather feather-lock">
                                    <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                                    <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                </svg>
                                <input id="password" *ngIf="!showPassword" name="password" type="password"
                                    class="form-control" placeholder="Password" formControlName="password"
                                    [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                                <input id="password" *ngIf="showPassword" name="password" type="text"
                                    class="form-control" placeholder="Password" formControlName="password"
                                    [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />

                                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                    <div *ngIf="f.password.errors.required">Password is required</div>
                                </div>
                                <svg (click)="showPass()" *ngIf="!f.password.errors" style="top: 41px;"
                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" id="toggle-password" class="feather feather-eye">
                                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                    <circle cx="12" cy="12" r="3"></circle>
                                    <line *ngIf="showPassword" x1="1" y1="1" x2="23" y2="23" />
                                </svg>

                            </div>
                            <div class="d-sm-flex justify-content-between">
                                <div class="field-wrapper">
                                    <button type="submit" class="btn btn-primary" value="">Log In</button>

                                </div>

                            </div>

                            <div class="division">
                                <div *ngIf="loading" class="spinner-border text-primary align-self-center"></div>
                            </div>

                            <!--<p class="signup-link">Not registered ? <a href="auth_register_boxed.html">Create an account</a></p>-->

                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>
</div>