<nav id="sidebar">

    <ul class="navbar-nav theme-brand flex-row  text-center">
        <li class="nav-item theme-logo">
            <a href="/dashboard">
                <!--<img src="assets/img/logo.png" class="navbar-logo" alt="logo">-->
            </a>
        </li>
        <li class="nav-item theme-text">
            <a href="/dashboard" class="nav-link"> Tax Volt </a>
        </li>
    </ul>

    <ul class="list-unstyled menu-categories" id="accordionExample">

        <li class="menu active">
            <a routerLink="/reporting" data-toggle="collapse" aria-expanded="true" class="dropdown-toggle">
                <div class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bell"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg>                     
                    <span>Reporting</span>
                </div>
            </a>
        </li>
        
        <!-- <li class="menu active">
            <a routerLink="/dashboard" data-toggle="collapse" aria-expanded="true" class="dropdown-toggle">
                <div class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                    <span>View Clients</span>
                </div>
              
            </a>
          
        </li> -->

        <li class="menu active">
            <a routerLink="/manage-ticket" data-toggle="collapse" aria-expanded="true" class="dropdown-toggle">
                <div class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                    <span>Manage Ticket</span>
                </div>
              
            </a>
          
        </li>

        <!-- <li class="menu active">
            <a href="#dashboard" data-toggle="collapse" aria-expanded="true" class="dropdown-toggle">
                <div class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bell"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg>                     
                    <span>Financial Planner Leads</span>
                </div>
                   <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>
                </div>
            </a>
            <ul class="collapse submenu recent-submenu mini-recent-submenu list-unstyled show" id="dashboard" data-parent="#accordionExample">
                <li>
                    <a [routerLink]="['/manage-rrsp']"> RRSP Lead</a>
                </li>                
                <li>
                    <a [routerLink]="['/manage-insurance']"> Insurance Lead </a>
                </li>
            </ul>
        </li> -->

        <li class="menu active">
            <a routerLink="/accounts-profile" data-toggle="collapse" aria-expanded="true" class="dropdown-toggle">
                <div class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                    <span>Manage Accountant Profiles</span>
                </div>
                <!--<div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>
                </div>-->
            </a>
            <!--<ul class="collapse submenu recent-submenu mini-recent-submenu list-unstyled show" id="dashboard" data-parent="#accordionExample">
                
                <li *ngIf="path == '/users'" class="active">
                    <a [routerLink]="['/users']"> User's List </a>
                </li>
                
                <li *ngIf="path != '/users'">
                    <a [routerLink]="['/users']"> User's List </a>
                </li>
            </ul>-->
        </li>

        <li class="menu active">
            <a routerLink="/financial-planner" data-toggle="collapse" aria-expanded="true" class="dropdown-toggle">
                <div class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>
                     <span>Manage Financial Planners</span>
                </div>
                    <!--<div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>
                </div>-->
            </a>
            <!--<ul class="collapse submenu recent-submenu mini-recent-submenu list-unstyled show" id="dashboard" data-parent="#accordionExample">
                
                <li *ngIf="path == '/catalog'" class="active">
                    <a [routerLink]="['/catalog']"> Cataloge's List </a>
                </li>
                
                <li *ngIf="path != '/catalog'">
                    <a [routerLink]="['/catalog']"> Cataloge's List </a>
                </li>
            </ul>-->
        </li>
        
        <!--<li class="menu active">
            <a routerLink="/view-t1-tax-requests" data-toggle="collapse" aria-expanded="true" class="dropdown-toggle">
                <div class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-save"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg>
                    <span>View T1 Tax Requests</span>
                </div>
                    <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>
                </div>
            </a>-->
           <!-- <ul class="collapse submenu recent-submenu mini-recent-submenu list-unstyled show" id="dashboard" data-parent="#accordionExample">
                
                <li *ngIf="path == '/auction'" class="active">
                    <a [routerLink]="['/auction']"> Auction ID's List </a>
                </li>
                
                <li *ngIf="path != '/auction'">
                    <a [routerLink]="['/auction']"> Auction ID's List </a>
                </li>
            </ul>
        </li>-->
 

       <!-- <li class="menu active">
            <a routerLink="/business-tax-prepration-requests" data-toggle="collapse" aria-expanded="true" class="dropdown-toggle">
                <div class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-folder-plus"><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path><line x1="12" y1="11" x2="12" y2="17"></line><line x1="9" y1="14" x2="15" y2="14"></line></svg> 
                    <span>View Business Tax Preparation</span>
                </div>
                 <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>
                </div>
            </a>-->
            <!--<ul class="collapse submenu recent-submenu mini-recent-submenu list-unstyled show" id="dashboard" data-parent="#accordionExample">
                
                <li *ngIf="path == '/keyword'" class="active">
                    <a [routerLink]="['/keyword']"> Keyword's List </a>
                </li>
                
                <li *ngIf="path != '/keyword'">
                    <a [routerLink]="['/keyword']"> Keyword's List </a>
                </li>

                <li *ngIf="path == '/condition'" class="active">
                    <a [routerLink]="['/condition']"> Condition's List </a>
                </li>
                
                <li *ngIf="path != '/condition'">
                    <a [routerLink]="['/condition']"> Condition's List </a>
                </li>


                <li *ngIf="path == '/pickup'" class="active">
                    <a [routerLink]="['/pickup']"> Pickup's List </a>
                </li>
                
                <li *ngIf="path != '/pickup'">
                    <a [routerLink]="['/pickup']"> Pickup's List </a>
                </li>
            </ul>
        </li>-->

        <li class="menu active">

            <div class="dropdown-toggle">
                <div class="dropdown-toggle">
                    <div class="tabs">
                        <div class="tab">
                  <input type="checkbox" id="chck1" hidden="true">
                  <label class="dropdown-toggle" for="chck1" style="cursor: pointer;">
                    <div class="dropdown-toggle">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bell"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg>                     
                        <span style="font-size: 13px;">Analytics</span>
                    
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>
                    </div>
                  </label>
                  <div class="tab-content">
                    
                        <span style="color: white;">Google Analytics</span>
                       <br><br>
                       <span style="color: white;">Crash Analytics</span>                      
                  </div>
                </div>
              </div>
            </div>
        </div>


            
            <!-- <a href="#dashboard" data-toggle="collapse" aria-expanded="true" class="dropdown-toggle" id="accordionExample">
                <div class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bell"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg>                     
                    <span>Analytics</span>
                </div>
                   <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>
                </div>
            </a>
            <ul class="collapse submenu recent-submenu mini-recent-submenu list-unstyled show" id="dashboard" data-parent="#accordionExample">
                <li *ngIf="path != '/dashboard'">
                    <a [routerLink]="['/dashboard']"> Google Analytics </a>
                </li>                
                <li *ngIf="path != '/dashboard'">
                    <a [routerLink]="['/dashboard']"> Crash Analytics </a>
                </li>
            </ul> -->
        </li>


        <li class="menu active">
            <a routerLink="/notification" data-toggle="collapse" aria-expanded="true" class="dropdown-toggle">
                <div class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bell"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg>                     
                    <span>Broadcast Notifications</span>
                </div>
            </a>
        </li>
        
    </ul>
    
</nav>
