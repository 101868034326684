<app-header></app-header>

<!--  BEGIN MAIN CONTAINER  -->
<div class="main-container sidebar-closed sbar-open" id="container">

    <div class="overlay"></div>
    <div class="cs-overlay"></div>
    <div class="search-overlay"></div>

    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">
        <app-navigation></app-navigation>
    </div>
    <!--  END SIDEBAR  -->

    <!--  BEGIN CONTENT AREA  -->
    <div id="content" class="main-content">
        <div class="layout-px-spacing">

            <div class="page-header">
                <div class="page-title">
                    <h3>Broadcast Notification</h3>
                </div>
                <button class="btn btn-primary mb-2 " style="float: right;
                margin-top: 21px;" (click)="createUser()">Add Notification</button>
            </div>
            <div class="row" id="cancel-row">

                <div class="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                    <div class="widget-content widget-content-area br-6">
                        <div class="table-responsive mb-4 mt-4">
                            <div id="zero-config_wrapper" class="dataTables_wrapper container-fluid dt-bootstrap4">
                                <div class="row">
                                    <div class="col-sm-12 col-md-6">
                                        <div class="dataTables_length" id="zero-config_length"><label>Results : <select
                                                    name="zero-config_length" aria-controls="zero-config"
                                                    class="form-control" (change)="changePerPageVal($event)">
                                                    <option value="5" *ngIf="perpage != 5">5</option>
                                                    <option value="5" *ngIf="perpage == 5" selected="selected">5
                                                    </option>
                                                    <option value="10" *ngIf="perpage != 10">10</option>
                                                    <option value="10" *ngIf="perpage == 10" selected="selected">10
                                                    </option>
                                                    <option value="20" *ngIf="perpage != 20">20</option>
                                                    <option value="20" *ngIf="perpage == 20" selected="selected">20
                                                    </option>
                                                    <option value="50" *ngIf="perpage != 50">50</option>
                                                    <option value="50" *ngIf="perpage == 50" selected="selected">50
                                                    </option>
                                                </select></label></div>
                                    </div>
                                    <div class="col-sm-12 col-md-6">
                                        <div id="zero-config_filter" class="dataTables_filter">
                                            <input type="search" (keyup)="text($event);" id="search" name="search"
                                                    class="form-control" placeholder="Search..." style="border-radius: 0rem"
                                                    aria-controls="zero-config">
                                            <button (click)="notificationsearch();" class="btn btn-primary mb-2 form-control" style="float: right; width: 50px; border-radius: 0rem"><svg
                                                     xmlns="http://www.w3.org/2000/svg" width="24"
                                                    height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-search" style="top: 8px">
                                                    <circle cx="11" cy="11" r="8"></circle>
                                                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                                </svg></button></div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <table id="zero-config" class="table table-hover dataTable" style="width: 100%;"
                                            role="grid" aria-describedby="zero-config_info">
                                            <thead>
                                                <tr role="row">
                                                    <th tabindex="0" aria-controls="zero-config" rowspan="1" colspan="1"
                                                        style="width: 50px;">Sr. No.</th>

                                                    <th tabindex="0" aria-controls="zero-config"
                                                        rowspan="1" colspan="1" style="width: 208px;">Title</th>
                                                    
                                                        <th tabindex="0"
                                                        aria-controls="zero-config" rowspan="1" colspan="1"
                                                        style="width: 178px;">Message</th>
                                                    
                                                        <th tabindex="0" aria-controls="zero-config" rowspan="1" colspan="1"
                                                        style="width: 126px;">Url</th>
                                                    
                                                        <th class="no-content sorting" tabindex="0"
                                                        aria-controls="zero-config" rowspan="1" colspan="1"
                                                        style="width: 39px;">ACTION</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr role="row"  *ngFor="let user of notificationList2 let i = index " >
                                                    <td>{{i+1}}</td>
                                                    <td>{{user.title}}</td>
                                                    <td>{{user.text}}</td>
                                                    <td>{{user.url}}</td>
                                                    <td>
                                                      <svg (click)="getuserlist(user._id);"
                                                            xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                            viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                            stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            class="feather feather-check-circle">

                                                            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                                            <polyline points="22 4 12 14.01 9 11.01"></polyline>
                                                        </svg>
                                                   
                                                        &nbsp; 
                                                    <!-- 
                                                        <svg 
                                                            xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                            viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                            stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            class="feather feather-x-circle table-cancel">
                                                            <circle cx="12" cy="12" r="10"></circle>
                                                            <line x1="15" y1="9" x2="9" y2="15"></line>
                                                            <line x1="9" y1="9" x2="15" y2="15"></line>
                                                        </svg> -->


                                                    </td>
                                                </tr>

                                            </tbody>
                                            <!--<tfoot>
                                                <tr>
                                                    <th rowspan="1" colspan="1">Name</th>
                                                    <th rowspan="1" colspan="1">Position</th>
                                                    <th rowspan="1" colspan="1">Office</th>
                                                    <th rowspan="1" colspan="1">Age</th>
                                                    <th rowspan="1" colspan="1">Start date</th>
                                                    <th rowspan="1" colspan="1">Salary</th>
                                                    <th rowspan="1" colspan="1"></th>
                                                </tr>
                                            </tfoot>-->
                                        </table>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md-5">
                                        <div class="dataTables_info" id="zero-config_info" role="status"
                                            aria-live="polite">Showing page {{page}} of {{numOfPage}}</div>
                                    </div>
                                    <div class="col-sm-12 col-md-7">
                                        <div class="dataTables_paginate paging_simple_numbers"
                                            id="zero-config_paginate">
                                            <ul class="pagination">
                                                <li *ngIf="page > 1" class="paginate_button page-item previous"
                                                    id="zero-config_previous"><a (click)="pagination('prev')"
                                                        aria-controls="zero-config" data-dt-idx="0" tabindex="0"
                                                        class="page-link"><svg xmlns="http://www.w3.org/2000/svg"
                                                            width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                            stroke="currentColor" stroke-width="2"
                                                            stroke-linecap="round" stroke-linejoin="round"
                                                            class="feather feather-arrow-left">
                                                            <line x1="19" y1="12" x2="5" y2="12"></line>
                                                            <polyline points="12 19 5 12 12 5"></polyline>
                                                        </svg></a></li>
                                                <li *ngIf="numOfPage >= 1 && page == 1"
                                                    class="paginate_button page-item active"><a (click)="pagination(1)"
                                                        aria-controls="zero-config" data-dt-idx="1" tabindex="0"
                                                        class="page-link">1</a></li>
                                                <li *ngIf="numOfPage >= 1 && page != 1"
                                                    class="paginate_button page-item"><a (click)="pagination(1)"
                                                        aria-controls="zero-config" data-dt-idx="1" tabindex="0"
                                                        class="page-link">1</a></li>


                                                <li *ngIf="numOfPage >= 2 && page == 2"
                                                    class="paginate_button page-item active"><a (click)="pagination(2)"
                                                        aria-controls="zero-config" data-dt-idx="2" tabindex="0"
                                                        class="page-link">2</a></li>
                                                <li *ngIf="numOfPage >= 2 && page != 2"
                                                    class="paginate_button page-item "><a (click)="pagination(2)"
                                                        aria-controls="zero-config" data-dt-idx="2" tabindex="0"
                                                        class="page-link">2</a></li>


                                                <li *ngIf="numOfPage >= 3 && page == 3"
                                                    class="paginate_button page-item active"><a (click)="pagination(3)"
                                                        aria-controls="zero-config" data-dt-idx="3" tabindex="0"
                                                        class="page-link">3</a></li>
                                                <li *ngIf="numOfPage >= 3 && page != 3"
                                                    class="paginate_button page-item "><a (click)="pagination(3)"
                                                        aria-controls="zero-config" data-dt-idx="3" tabindex="0"
                                                        class="page-link">3</a></li>

                                                <li *ngIf="numOfPage >= 4 && page == 4"
                                                    class="paginate_button page-item active"><a (click)="pagination(4)"
                                                        aria-controls="zero-config" data-dt-idx="4" tabindex="0"
                                                        class="page-link">4 </a></li>
                                                <li *ngIf="numOfPage >= 4 && page != 4"
                                                    class="paginate_button page-item "><a (click)="pagination(4)"
                                                        aria-controls="zero-config" data-dt-idx="4" tabindex="0"
                                                        class="page-link">4 </a></li>

                                                <li *ngIf="numOfPage >= 5 && page == 5"
                                                    class="paginate_button page-item active"><a (click)="pagination(5)"
                                                        aria-controls="zero-config" data-dt-idx="5" tabindex="0"
                                                        class="page-link">5</a></li>
                                                <li *ngIf="numOfPage >= 5 && page != 5"
                                                    class="paginate_button page-item "><a (click)="pagination(5)"
                                                        aria-controls="zero-config" data-dt-idx="5" tabindex="0"
                                                        class="page-link">5</a></li>
                                                <li></li>
                                                <li *ngIf="page > 5" class="paginate_button page-item active"><a
                                                        (click)="pagination(page)" aria-controls="zero-config"
                                                        data-dt-idx="5" tabindex="0" class="page-link">{{page}}</a></li>
                                                <li *ngIf="page > 5"></li>
                                                <li *ngIf="numOfPage > 5  && page < numOfPage"
                                                    class="paginate_button page-item next" id="zero-config_next"><a
                                                        (click)="pagination('next')" aria-controls="zero-config"
                                                        data-dt-idx="6" tabindex="0" class="page-link"><svg
                                                            xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                            viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                            stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round" class="feather feather-arrow-right">
                                                            <line x1="5" y1="12" x2="19" y2="12"></line>
                                                            <polyline points="12 5 19 12 12 19"></polyline>
                                                        </svg></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
    <!--  END CONTENT AREA  -->

</div>
<!-- END MAIN CONTAINER -->


<div class="modal fade bd-example-modal-lg" id="createUserModal" tabindex="-1" role="dialog"
    aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="myLargeModalLabel">Add Notification</h5>
                <button type="button" class="close" (click)="dismissModal()" aria-label="Close">
                    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" class="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                </button>
            </div>
            <form [formGroup]="notificationForm" (ngSubmit)="onSubmit()">
                <div class="modal-body">

                    <div class="form-row mb-4">
                        <div class="col">
                            <label>Enter Title</label>
                            <input class="form-control" formControlName="title" placeholder="Enter Title"
                                [ngClass]="{ 'is-invalid': submitted && f.title.errors }">
                            <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                                <div *ngIf="f.title.errors.required">Title is required</div>
                            </div>
                        </div>

                    </div>

                    <div class="form-row mb-4">

                        <div class="col">
                            <label>Enter Notifications Text</label>
                            <textarea class="form-control" formControlName="notificationText" placeholder="Enter Notifications Text"
                                [ngClass]="{ 'is-invalid': submitted && f.notificationText.errors }"></textarea>
                            <div *ngIf="submitted && f.notificationText.errors" class="invalid-feedback">
                                <div *ngIf="f.notificationText.errors.required">Notifications Text is required</div>
                            </div>
                        </div>

                    </div>

                    <div class="form-row mb-4">
                        <div class="col">
                            <label>Enter URL</label>
                            <input class="form-control" formControlName="url" placeholder="Enter URL"
                                [ngClass]="{ 'is-invalid': submitted && f.url.errors }">
                            <div *ngIf="submitted && f.url.errors" class="invalid-feedback">
                                <div *ngIf="f.url.errors.required">URL is required</div>
                            </div>
                        </div>

                    </div>
             </div>
            <div class="modal-footer">
                    <button class="btn" type="button" (click)="dismissModal()"><i class="flaticon-cancel-12"></i>
                        Discard</button>
                    <button type="submit" class="btn btn-primary">Add Notifications</button>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="modal fade reset-example-modal-lg" id="getnotificationuserlist" tabindex="-1" role="dialog"
aria-labelledby="myLargeModalLabel" aria-hidden="true">
<div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="myLargeModalLabel">UserList</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" class="feather feather-x">
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
            </button>
        </div>
        <div class="modal-body">

                <div class="row">
                    <div class="col-sm-12 col-md-6 pull-right">
                        <div id="zero-config_filter" class="dataTables_filter"><label>
                                <input (keyup)="searchuserdata($event);" type="text" id="search" name="search"
                                    class="form-control" placeholder="Search..."
                                    aria-controls="zero-config"></label>
                        </div>
                     </div>
                    <div class="col-sm-12">
                       Title   :    {{ getmessagedata?getmessagedata[0].title:''}} <br> Message  :  {{getmessagedata?getmessagedata[0].text:''}}
                        <button class="btn btn-info" (click)="sendpushnotification();">Send Notification</button>
                           <table id="zero-config" class="table table-hover dataTable" style="width: 100%;"
                            role="grid" aria-describedby="zero-config_info">
                            <thead>
                                <tr role="row">
                                  
                                    <th tabindex="0" aria-controls="zero-config" rowspan="1" colspan="1"
                                        style="width: 100px;">No.</th>
                                        <th style="width: 220px;"> <input type="checkbox"  name="list_name" value="m1"  id="checkAll"  (change)="checkUncheckAll($event)" /> <strong style="margin-left: 10px;">Select All</strong></th>
                                        <th tabindex="0" aria-controls="zero-config"
                                        rowspan="1" colspan="1" style="width: 208px;">Email</th>

                                        <th tabindex="0" aria-controls="zero-config"
                                        rowspan="1" colspan="1" style="width: 208px;">Username</th>

                                        <th tabindex="0" aria-controls="zero-config"
                                        rowspan="1" colspan="1" style="width: 208px;">Usertype</th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr role="row"  *ngFor="let user of userAlllist let i = index " >
                                    <td>{{i+1}}</td>
                                    <td><input type="checkbox"  name="list_name" (change)="onCheckboxChange($event)" value="{{user._id}}"/></td>
                                    <td>{{user.email}}</td>
                                    <td>{{user.username}}</td>
                                    <td>{{user.userType}}</td>
                                </tr>

                            </tbody>
                    
                        </table>
                    </div>
                </div> 
          
    </div>
     
    </div>
</div>
</div>