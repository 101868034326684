import { NgModule } from '@angular/core';
import { Routes, RouterModule,PreloadAllModules } from '@angular/router';
import { AuthGuard } from './helpers/auth.guard';

import { LoginComponent } from './modules/login/login.component'
import { DashboardComponent } from './modules/dashboard/dashboard.component'
import { UsersComponent } from './modules/users/users.component'
import { BusinessServicesComponent } from './modules/business-services/business-services.component'
import { AccountsProfileComponent } from './modules/accounts-profile/accounts-profile.component'
import { FinancialPlannerComponent } from './modules/financial-planner/financial-planner.component'
import { ViewTaxComponent } from './modules/view-tax/view-tax.component'
import { BusinessTaxComponent } from './modules/business-tax/business-tax.component'
import { NotificationComponent } from './modules/notification/notification.component'
import { ReportingComponent } from './modules/reporting/reporting.component'
import { ManageTicketsComponent } from './modules/manage-tickets/manage-tickets.component'
import { ViewTicketComponent } from './modules/view-ticket/view-ticket.component'
import { AssignRrsp } from './modules/assign-rrsp/assign-rrsp.component'
import { AssignInsurance } from './modules/assign-insurance/assign-insurance.component'
import { ManageInsuranceComponent } from './modules/manage-insurance/manage-insurance.component'
import { ManageRrspComponent } from './modules/manage-rrsp/manage-rrsp.component'



const routes: Routes = [
  { path: 'login',  component: LoginComponent },
  { path: 'dashboard',  component: DashboardComponent,canActivate:[AuthGuard]  },
  { path: 'view-t1-requests',  component: UsersComponent,canActivate:[AuthGuard] },
  { path: 'business-services',  component: BusinessServicesComponent,canActivate:[AuthGuard] }, 
  { path: 'accounts-profile',  component: AccountsProfileComponent,canActivate:[AuthGuard] },   
  { path: 'financial-planner',  component: FinancialPlannerComponent,canActivate:[AuthGuard] },  
  { path: 'view-t1-tax-requests',  component: ViewTaxComponent,canActivate:[AuthGuard] },   
  { path: 'business-tax-prepration-requests',  component: BusinessTaxComponent,canActivate:[AuthGuard] }, 
  { path: 'notification',  component: NotificationComponent,canActivate:[AuthGuard] },
  { path: 'reporting',  component: ReportingComponent },   
  { path: 'manage-ticket',  component: ManageTicketsComponent },
  { path: 'manage-insurance',  component: ManageInsuranceComponent }, 
  { path: 'manage-rrsp',  component: ManageRrspComponent },     
  { path: 'view-ticket/:id/:view',  component: ViewTicketComponent }, 
  { path: 'assign-insurance/:id/:view',  component: AssignInsurance }, 
  { path: 'assign-rrsp/:id/:view',  component: AssignRrsp },          
  { path: '',   redirectTo: '/login', pathMatch: 'full' },
  { path: '**', component: LoginComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
